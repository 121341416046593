<!-- AdComponent.vue -->
<template>
  <ins class="adsbygoogle"
       style="display:block"
       :data-ad-client="adClient"
       :data-ad-slot="adSlot"
       data-ad-format="auto"
       data-full-width-responsive="true">
  </ins>
</template>

<script>
export default {
  name: 'AdComponent',
  props: {
    adClient: String,
    adSlot: String
  },
  mounted() {
    // (adsbygoogle = window.adsbygoogle || []).push({});
  }
}
</script>
