<template>
  <div class="container mt-3 mb-5">
    <AdSection />
    <Hotels />
    <GoogleAds ad-client="ca-pub-6711612485896577" ad-slot="9337625230"/>
    <PromoCards />
  </div>
</template>

<script>
import Hotels from "@/components/Home/Hotels.vue";
import AdSection from "@/components/AdSection.vue";
import PromoCards from "@/components/PromoCards.vue";
import GoogleAds from "@/components/GoogleAds.vue";
export default {
  name: "HotelsPage",
  components: {
    GoogleAds,
    PromoCards,
    Hotels,
    AdSection,
  },
  data() {
    return {};
  },
};
</script>
