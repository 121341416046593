<template>
  <div
    class="d-flex justify-content-between text-center pt-5"
    v-for="(img, index) in bannerImg"
    :key="index"
  >
    <img :src="backendUrl + img.images" alt="img" class="rounded-4 bg-img" />
    <button
      type="button"
      v-if="isAdmin"
      class="btn btn-primary h-100"
      data-bs-toggle="modal"
      data-bs-target="#bannerModal"
    >
      <i class="fa-solid fa-pen-to-square"></i>
    </button>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="bannerModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Change Banner Image</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label for="img">Image</label>
              <input
                type="file"
                id="img"
                class="form-control"
                accept=".jpg, .jpeg, .png"
                @change="handleFileChange"
              />
              <!-- Image preview -->
              <div
                v-if="isLoading"
                class="spinner-border text-primary mt-3"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="!isLoading && imageUrl" class="position-relative">
                <img :src="imageUrl" class="mt-3" style="max-width: 20%" />
                <button
                  class="btn btn-sm btn-danger m-2 cross"
                  @click="clearImage"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateCard"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";

export default {
  name: "ImageBanner",
  data() {
    return {
      backendUrl: process.env.VUE_APP_IMAGE_URL,
      cardFor: "banner img",
      imageUrl: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getImg();
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    bannerImg() {
      return useUserInfoStore().bannerImg;
    },
    token() {
      return useUserInfoStore().userToken();
    },
  },
  methods: {
    async getImg() {
      try {
        const data = useUserInfoStore().bannerImg;
        if (data.length === 0) {
          const url =
            process.env.VUE_APP_API_URL +
            `/card/get/banner_img/${this.language}`;
          const response = await axios.get(url);
          useUserInfoStore().setBannerImg(response.data);
        }
      } catch (error) {
        console.error("Error on getting card:", error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.img = file;
      this.imageChanged = true;
      this.isLoading = true;
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.isLoading = false;
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.imageUrl = null;
      const input = document.getElementById("img");
      if (input) {
        input.value = "";
      }
    },
    async updateCard() {
      if (this.token && this.bannerImg) {
        const formData = new FormData();
        formData.append("id", this.bannerImg[0].id);
        formData.append("images", this.img);

        try {
          const headers = {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data",
          };
          const url = `${process.env.VUE_APP_API_URL}/card/update`;
          const response = await axios.post(url, formData, { headers });
          useUserInfoStore().updateBannerImg(0, response.data);

          console.log("Updated Banner Image:", useUserInfoStore().bannerImg[0]);
        } catch (error) {
          console.error("Error updating card:", error);
        }
      }
    },
  },
};
</script>
