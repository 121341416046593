import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/views/layout1.vue";
import HomePage from "@/pages/HomePage.vue";
import FlightsPage from "@/pages/FlightsPage.vue";
import HotelsPage from "@/pages/HotelsPage.vue";
import RidesPage from "@/pages/RidesPage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import HelpPage from "@/pages/HelpPage.vue";
import forgetPassword from "@/pages/forgetPassword.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";
import TermsConditions from "@/pages/TermsConditions.vue";
import CookiePolicy from "@/pages/CookiePolicy.vue";
import CompanyDetails from "@/pages/CompanyDetails.vue";

import ProfilePage from "@/pages/ProfilePage.vue";
import MyTrips from "@/pages/profile/MyTrips.vue";
import MyRewards from "@/pages/profile/MyRewards.vue";
import MyDeals from "@/pages/profile/MyDeals.vue";
import MyInformation from "@/pages/profile/MyInformation.vue";
import Settings from "@/pages/profile/Settings.vue";
import { useUserInfoStore } from "@/stores/userInfoStore";
const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/",
        redirect: "/home",
      },
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/flights",
        name: "FlightsPage",
        component: FlightsPage,
      },
      {
        path: "/hotels",
        name: "HotelsPage",
        component: HotelsPage,
      },
      {
        path: "/rides",
        name: "RidesPage",
        component: RidesPage,
      },
      {
        path: "/about-us",
        name: "AboutPage",
        component: AboutPage,
      },
      {
        path: "/help",
        name: "HelpPage",
        component: HelpPage,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: CookiePolicy,
      },
      {
        path: "/terms-conditions",
        name: "TermsConditions",
        component: TermsConditions,
      },
      {
        path: "/company-details",
        name: "CompanyDetails",
        component: CompanyDetails,
      },
      {
        path: "/forget-password",
        name: "forgetPassword",
        component: forgetPassword,
      },
      // {
      //   path: "/profile",
      //   name: "ProfilePage",
      //   component: ProfilePage,
      // },
      // {
      //   path: "/trips",
      //   name: "MyTrips",
      //   component: MyTrips,
      // },
      // {
      //   path: "/rewards",
      //   name: "MyRewards",
      //   component: MyRewards,
      // },
      // {
      //   path: "/deals",
      //   name: "MyDeals",
      //   component: MyDeals,
      // },
      {
        path: "/information",
        name: "MyInformation",
        component: MyInformation,
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/settings",
      //   name: "Settings",
      //   component: Settings,
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const userInfoStore = useUserInfoStore(); // Use the store
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !userInfoStore.isLoggedIn) {
    next("/home"); // Redirect to login if not authenticated
  } else {
    next(); // Proceed normally
  }
});
export default router;
