<template>
  <div
    class="modal fade"
    id="cardModal"
    tabindex="-1"
    aria-labelledby="cardModalLabel"
    aria-hidden="true"
    @shown.bs.modal="handleModalShow"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="cardModalLabel">
            {{ actionType === "edit" ? "Edit Card" : "Add Card" }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearCurrentImage"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <label for="title">Title</label>
                <input
                  type="text"
                  id="title"
                  class="form-control mb-3"
                  placeholder="Enter Title"
                  v-model="data.title"
                />
              </div>
              <div class="col-md-6">
                <label for="desc">Description</label>
                <input
                  type="text"
                  id="desc"
                  class="form-control mb-3"
                  placeholder="Enter Description"
                  v-model="data.description"
                />
              </div>
              <div class="col-md-6">
                <label for="city">City</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Enter City"
                  v-model="data.city"
                />
              </div>
              <div class="col-md-6 align-self-center">
                <label for="status">Status: </label>
                <div class="form-check form-check-inline ms-3" id="status">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="active"
                    v-model="data.status"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="inactive"
                    v-model="data.status"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Inactive
                  </label>
                </div>
              </div>
              <div class="col-12">
                <label for="img">Image</label>
                <input
                  type="file"
                  id="img"
                  class="form-control"
                  accept=".jpg, .jpeg, .png"
                  @change="handleFileChange"
                  ref="fileInput"
                />
                <!-- Image preview -->
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary mt-3"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div v-if="!isLoading && imageUrl" class="position-relative">
                  <img :src="imageUrl" class="mt-3" style="max-width: 20%" />
                  <!-- Cross button -->
                  <button
                    class="btn btn-sm btn-danger m-2 cross"
                    @click="clearImage"
                  >
                    &times;
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearCurrentImage"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="saveChanges"
            :disabled="!isFormValid()"
          >
            {{ actionType === "edit" ? "Save Changes" : "Add Card" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";

export default {
  name: "CardModal",
  props: {
    selectedCard: {
      type: Object,
    },
    selectedCardIndex: {
      type: Number,
    },
    actionType: {
      type: String,
    },
    cardFor: {
      type: String,
    },
  },
  data() {
    return {
      imageUrl: null,
      isLoading: false,
      data: {
        title: "",
        description: "",
        city: "",
        status: "active",
        img: "",
      },
    };
  },
  watch: {
    actionType() {
      this.clearData();
      this.fetchDataFromCard();
    },
    selectedCard(after, before) {
      this.fetchDataFromCard();
      console.log(after, before);
    },
  },
  computed: {
    token() {
      return useUserInfoStore().userToken();
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
  },
  methods: {
    isFormValid() {
      return (
        this.data.title &&
        this.data.description &&
        this.data.city &&
        this.data.status &&
        this.imageUrl
      );
    },

    saveChanges() {
      if (this.actionType === "edit") {
        this.updateCard();
      } else {
        this.addCard();
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.img = file;
        this.imageChanged = true;
        this.isLoading = true;
        this.previewImage(file);
      }
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.isLoading = false;
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.imageUrl = null;
      this.img = null;
      const input = document.getElementById("img");
      if (input) {
        input.value = "";
      }
      this.$refs.fileInput.value = "";
    },
    clearCurrentImage() {
      if (this.actionType === "edit") {
        this.imageUrl = this.selectedCard.images
          ? process.env.VUE_APP_IMAGE_URL + this.selectedCard.images
          : null;
        this.$refs.fileInput.value = "";
      }
      this.clearData();
    },
    clearData() {
      if (this.actionType !== "edit") {
        this.data.title = "";
        this.data.description = "";
        this.data.city = "";
        this.data.status = "active";
        this.data.img = "";
        this.imageUrl = "";
        this.$refs.fileInput.value = "";
      }
    },
    handleModalShow() {
      if (this.actionType !== "edit") {
        this.clearData();
      }
    },
    fetchDataFromCard() {
      if (this.actionType === "edit") {
        this.data = {
          title: this.selectedCard.title || "",
          description: this.selectedCard.description || "",
          city: this.selectedCard.city || "",
          status: this.selectedCard.status || "active",
          img: null,
        };
        this.imageUrl = this.selectedCard.images
          ? process.env.VUE_APP_IMAGE_URL + this.selectedCard.images
          : null;
        this.originalData = { ...this.data };
      }
    },
    async addCard() {
      const token = this.token;
      console.log("token is", token);
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        try {
          const formData = new FormData();
          formData.append("title", this.data.title);
          formData.append("description", this.data.description);
          formData.append("city", this.data.city);
          formData.append("status", this.data.status);
          formData.append("card_for", this.cardFor);
          formData.append("images", this.img);

          const url = process.env.VUE_APP_API_URL + "/card/add";
          const response = await axios.post(url, formData, {
            headers: headers,
          });
          toast.success(this.isLanguage["Card Added Successfully."]);
          if (this.cardFor === "flights") {
            useUserInfoStore().flights.push(response.data);
          } else if (this.cardFor === "hotels") {
            useUserInfoStore().hotels.push(response.data);
          } else if (this.cardFor === "rides") {
            useUserInfoStore().rides.push(response.data);
          }
          this.clearData();
        } catch (error) {
          toast.error(this.isLanguage["Error adding card"]);
        }
      }
    },
    async updateCard() {
      if (this.token && this.selectedCard) {
        const formData = new FormData();
        formData.append("id", this.selectedCard.id);

        for (const key in this.data) {
          if (this.data[key] !== this.originalData[key]) {
            formData.append(key, this.data[key]);
          }
        }

        if (this.imageChanged && this.img) {
          formData.append("images", this.img);
        }

        try {
          const headers = {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data",
          };
          const url = `${process.env.VUE_APP_API_URL}/card/update`;
          const response = await axios.post(url, formData, { headers });
          if (this.cardFor === "flights") {
            useUserInfoStore().updateFlight(
              this.selectedCardIndex,
              response.data,
            );
          } else if (this.cardFor === "hotels") {
            useUserInfoStore().updateHotels(
              this.selectedCardIndex,
              response.data,
            );
          } else if (this.cardFor === "rides") {
            useUserInfoStore().updateRides(
              this.selectedCardIndex,
              response.data,
            );
          } else if (this.cardFor === "flightAds") {
            useUserInfoStore().updateFlightAds(
              this.selectedCardIndex,
              response.data,
            );
          }

          this.clearData();
          toast.success(this.isLanguage["Card Updated Successfully."]);
          this.$emit("updateCard", response.data);
        } catch (error) {
          toast.error(this.isLanguage["Error updating card"]);
        }
      }
    },
  },
};
</script>
