<template>
    <MainNav />
    <router-view></router-view>
    <MainFooter />
</template>

<script>
import MainFooter from '@/components/MainFooter.vue';
import MainNav from '@/components/MainNav.vue';

export default {
    name: 'MainLayout',
    components: {
        MainNav,
        MainFooter
    }
}
</script>