// src/encryption.js
import CryptoJS from 'crypto-js';

const key = 'Sasystems123'; // Replace with your own secret key

// Function to encrypt data using AES
export const decryptionFunctions = {
 encryptData(data) {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return encrypted;
},
// Function to decrypt data using AES
 decryptData(encryptedData) {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  },

};