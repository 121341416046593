<template>
  <div class="card border-0 shadow">
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <!-- Check if userdata.image exists -->
          <img
            v-if="userdata.image"
            :src="backendUrl + userdata.image"
            alt=""
            class="user-image"
          />
          <!-- If userdata.image does not exist, show default avatar -->
          <img
            v-else
            src="/avatar.jpg"
            alt="Default Avatar"
            class="user-image"
          />
          {{ userdata.first_name }}
          {{ userdata.last_name === null ? "" : userdata.last_name }}
        </li>
        <router-link
          to="information"
          active-class="active"
          class="list-group-item list-group-item-action"
        >
          <i class="fa-solid fa-user me-3"></i> {{ isLanguage['My Information'] }}
        </router-link>
        <!--        <router-link-->
        <!--          to="/trips"-->
        <!--          class="list-group-item list-group-item-action"-->
        <!--          aria-current="true"-->
        <!--          @click="comingSoon"-->
        <!--        >-->
        <li class="list-group-item cursor-pointer" @click="comingSoon">
          <i class="fa-solid fa-suitcase me-3"></i> {{ isLanguage['My Trips'] }}
        </li>
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--          to="/rewards"-->
        <!--          active-class="active"-->
        <!--          class="list-group-item list-group-item-action"-->
        <!--        >-->
        <li class="list-group-item cursor-pointer" @click="comingSoon">
          <i class="fa-solid fa-gift me-3"></i> {{ isLanguage['My Rewards'] }}
        </li>
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--          to="/deals"-->
        <!--          active-class="active"-->
        <!--          class="list-group-item list-group-item-action"-->
        <!--        >-->
        <li class="list-group-item cursor-pointer" @click="comingSoon">
          <i class="fa-solid fa-tags me-3"></i> {{ isLanguage['My Deals'] }}
        </li>
        <!--        </router-link>-->

        <!--        <router-link-->
        <!--          to="settings"-->
        <!--          active-class="active"-->
        <!--          class="list-group-item list-group-item-action"-->
        <!--        >-->
        <!--          <i class="fa-solid fa-gear me-3"></i> Settings-->
        <!--        </router-link>-->
      </ul>
    </div>
  </div>
</template>
<script>
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";
export default {
  name: "ProfileTabs",
  data() {
    return {
      backendUrl: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    userdata() {
      return useUserInfoStore().user;
    },
    isLanguage(){
      return useUserInfoStore().isLanguage
    },
  },
  methods: {
    comingSoon() {
      toast.success(this.isLanguage["Coming Soon"]);
    },
  },
};
</script>
