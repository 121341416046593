<template>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div
        class="card border-0 shadow"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-2 col-12 d-flex align-self-center justify-content-md-end justify-content-center"
            >
              <img
                src="/add-appdownloadbanner.jpeg"
                alt="img"
                class="small-img"
              />
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-7">
              <p v-html="pageContent3.body"></p>
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary btn-sm" @click="installApp">
                  {{ isLanguage['Install App'] }}
                </button>
              </div>
            </div>
            <div class="col-md-2 text-end">
              <button
                type="button"
                v-if="isAdmin"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#promo3"
                @click="openModal3"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-1"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="promo3"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent3.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor :editor="editor" v-model="editedContent3"></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent3.id, pageContent3.title, editedContent3)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";

export default {
  name: "PromoCards",
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContent3: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    pageContent3() {
      return useUserInfoStore().promo3;
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
  },
  methods: {
    openModal3() {
      this.editedContent3 = this.pageContent3.body;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating content:", error);
        });
    },
  },
};
</script>
