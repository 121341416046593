<template>
  <div id="hotels" class="row mt-5 mb-5">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <p v-html="pageContent.body"></p>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary ms-4"
            data-bs-toggle="modal"
            data-bs-target="#hotelsTitle"
            @click="openModal"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div v-if="showButtons && isAdmin">
          <label for="status" class="me-3 fs-5">Status: </label>
          <div class="form-check form-check-inline align-self-center">
            <input
              class="form-check-input"
              type="radio"
              id="active"
              value="active"
              v-model="filterStatus"
              @change="handleFilterChange"
            />
            <label class="form-check-label" for="active">Active</label>
          </div>
          <div class="form-check form-check-inline align-self-center me-5">
            <input
              class="form-check-input"
              type="radio"
              id="inactive"
              value="inactive"
              v-model="filterStatus"
              @change="handleFilterChange"
            />
            <label class="form-check-label" for="inactive">Inactive</label>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary z-3"
            data-bs-toggle="modal"
            data-bs-target="#cardModal"
            v-if="showButtons && isAdmin"
            @click="addCard('add')"
          >
            Add Card
          </button>
        </div>
      </div>
    </div>
    <div
      v-for="(hotel, index) in filteredCards"
      :key="index"
      class="col-md-4 mb-4 d-flex align-items-stretch"
    >
      <div
        class="card border-0 shadow cursor-pointer"
        data-aos="fade-right"
        data-aos-offset="50"
        data-aos-delay="50"
        data-aos-duration="2000"
        data-aos-easing="ease-in-out"
        @click="handleHotels(hotel.city)"
      >
        <img :src="imageurl + hotel.images" class="card-img-top" alt="img" />
        <div class="card-body">
          <h5 class="card-title">{{ hotel.title }}</h5>
          <div class="d-flex justify-content-between">
            <p class="card-text">{{ hotel.description }}</p>
            <button
              type="button"
              v-if="showButtons && isAdmin"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#cardModal"
              @click.prevent="editCard(hotel, 'edit', index)"
              @click.stop
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CardModal
    :selectedCard="selectedCard"
    :cardFor="cardFor"
    :actionType="actionType"
    :selectedCardIndex="selectedCardIndex"
  />

  <!-- Modal -->
  <div
    class="modal fade"
    id="hotelsTitle"
    tabindex="-1"
    aria-labelledby="hotelsTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            v-model="editedContent"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent.id, pageContent.title, editedContent)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { toast } from "vue3-toastify";
import CardModal from "@/components/CardModal.vue";
import { useUserInfoStore } from "@/stores/userInfoStore";
import axios from "axios";

export default {
  name: "Hotels",
  components: { CardModal, ckeditor: CKEditor.component },
  data() {
    return {
      imageurl: process.env.VUE_APP_IMAGE_URL,
      selectedCard: [],
      actionType: "",
      cardFor: "hotels",
      showButtons: true,
      selectedCardIndex: "",
      filterStatus: "active",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContent: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    hotels() {
      return useUserInfoStore().hotels;
    },
    token() {
      return useUserInfoStore().userToken();
    },
    pageContent() {
      return useUserInfoStore().hotelsTitle;
    },
    formatDate() {
      return useUserInfoStore().formatDate;
    },
    filteredCards() {
      if (this.filterStatus === "active") {
        return this.hotels.filter((hotel) => hotel.status === "active");
      } else if (this.filterStatus === "inactive") {
        return this.hotels.filter((hotel) => hotel.status === "inactive");
      }
      return this.hotels;
    },
  },
  mounted() {
    this.getHotels();
    if (this.$route.path === "/home") {
      this.showButtons = false;
    }
  },
  methods: {
    async getHotels() {
      try {
        const data = useUserInfoStore().hotels;
        if (data.length === 0) {
          useUserInfoStore().cardsIsLoading = true;
          const url =
            process.env.VUE_APP_API_URL + `/card/get/hotels/${this.language}`;
          const response = await axios.get(url);
          await useUserInfoStore().setHotels(response.data);
          useUserInfoStore().cardsIsLoading = false;
        }
      } catch (error) {
        console.error("Error on getting flights:", error);
      }
    },
    async handleFilterChange() {
      if (this.filterStatus === "active") {
        await this.getFilteredCards("active");
      } else if (this.filterStatus === "inactive") {
        await this.getFilteredCards("inactive");
      }
    },
    async getFilteredCards(status) {
      try {
        const url =
          process.env.VUE_APP_API_URL + `/card/filter/hotels/${status}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        useUserInfoStore().setHotels(response.data);
      } catch (error) {
        console.error(`Error on getting ${status} cards:`, error);
      }
    },
    editCard(data, actionType, index) {
      this.selectedCard = data;
      this.actionType = actionType;
      this.selectedCardIndex = index;
    },
    addCard(actionType) {
      this.actionType = actionType;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating content:", error);
        });
    },
    openModal() {
      this.editedContent = this.pageContent.body;
    },
    handleHotels(cityCode) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const afterOneWeek = new Date();
      afterOneWeek.setDate(afterOneWeek.getDate() + 7);
      const tomorrowFormatted = this.formatDate(tomorrow);
      const afterOneWeekFormatted = this.formatDate(afterOneWeek);
      const url = `https://flight.airbookings.ca/htltravel.htm?lang=${this.language}-us&rest=true#search|HotelCityCode=&PlaceId=${cityCode}&Latitude=&Longitude=&Adults=1&Children=0&CheckInDate=${tomorrowFormatted}&CheckOutDate=${afterOneWeekFormatted}&RadiusSize=25&RadiusUnit=km&PostalCode=&StreetAddress=&HotelName=&RoomCount=1&PropertyCode=`;
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.card-img-top {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 786px) {
  .card-img-top {
    width: 350px;
    height: 300px;
    object-fit: cover;
  }
}
</style>
