<template>
  <div class="pt-5">
    <h5 class="fw-bold">{{ pageContent.title }}</h5>
    <div class="ms-3 d-flex justify-content-between">
      <p v-html="pageContent.body"></p>
      <button
        type="button"
        v-if="isAdmin"
        class="btn btn-primary h-100"
        data-bs-toggle="modal"
        data-bs-target="#faqModal"
        @click="openModal"
      >
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="faqModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            v-model="editedContent"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent.id, pageContent.title, editedContent)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";

export default {
  name: "FAQsSection",
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContent: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    pageContent() {
      return useUserInfoStore().faq;
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
  },

  methods: {
    openModal() {
      this.editedContent = this.pageContent.body;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating content:", error);
        });
    },
  },
};
</script>
