<template>
  <footer class="footer mt-auto py-5 text-white bg-color-primary shadow-lg">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6">
          <h5 class="text-uppercase">{{ isLanguage["LET’S CONNECT"] }}</h5>
          <ul class="list-unstyled">
            <li>
              <a href="/help" class="text-white text-decoration-none">{{
                isLanguage["Help"]
              }}</a>
            </li>
            <li>
              <a
                href="terms-conditions"
                class="text-white text-decoration-none"
              >
                {{ isLanguage["Terms & Conditions"] }}
              </a>
            </li>
            <li>
              <a href="/about-us" class="text-white text-decoration-none">
                {{ isLanguage["About"] }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-6">
          <h5 class="text-uppercase">{{ isLanguage["LEGAL"] }}</h5>
          <ul class="list-unstyled">
            <li>
              <a href="/cookie-policy" class="text-white text-decoration-none">
                {{ isLanguage["Cookie Policy"] }}
              </a>
            </li>
            <li>
              <a href="/privacy-policy" class="text-white text-decoration-none">
                {{ isLanguage["Privacy policy"] }}
              </a>
            </li>
            <li>
              <a
                href="/company-details"
                class="text-white text-decoration-none"
              >
                {{ isLanguage["Company"] }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-12">
          <h5 class="text-uppercase">{{ isLanguage["PARTNERS"] }}</h5>
          <ul class="list-unstyled">
            <li>
              <div
                class="d-md-flex gap-3 align-items-center justify-content-center partners"
              >
                <img src="/mastercard.png" alt="" width="80" />
                <img src="/visa.png" alt="" width="80" />
                <img src="/IATA.png" alt="" width="80" />
                <img src="/pci.png" alt="" width="80" />
                <img src="/Amadeus.png" alt="" width="80" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col text-end">
          <h5 class="text-uppercase">{{ isLanguage["ABOUT US"] }}</h5>
          <ul class="list-unstyled">
            <li>
              <a
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                class="text-white text-decoration-none cursor-pointer"
                v-if="!isLoggedIn"
              >
                {{ isLanguage["Log in"] }}
              </a>
            </li>
            <li class="text-white">© 2024 AIRBOOKINGS CA</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useUserInfoStore } from "@/stores/userInfoStore";

export default {
  name: "MainFooter",
  computed: {
    userdata() {
      return useUserInfoStore().user;
    },
    isLoggedIn() {
      return useUserInfoStore().isLoggedIn;
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
  },
};
</script>

<style scoped>
.text-white {
  text-shadow: 3px 3px 5px #ffffffb6;
}
</style>
