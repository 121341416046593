<template>
  <Widget />
  <div class="container margin-top-7 overflow-x-hidden">
    <Flights />

    <Features />

    <GoogleAds ad-client="ca-pub-6711612485896577" ad-slot="9337625230" />

    <div class="row mt-md-0 mt-5">
      <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-3">
        <a href="#thomalex-widget" class="text-decoration-none text-reset">
          <div
            class="card text-white bg-color-primary"
            data-aos="fade-down-right"
            data-aos-offset="80"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <div class="card-body">
              <i class="fa-solid fa-globe"></i>
              {{ isLanguage["Explore Everywhere"] }}
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-3">
        <a href="#hotels" class="text-decoration-none text-reset">
          <div
            class="card text-white bg-color-primary"
            data-aos="fade-down-left"
            data-aos-offset="80"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <div class="card-body">
              <i class="fa-solid fa-bed"></i> {{ isLanguage["Hotels"] }}
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-3">
        <a href="#car-hire" class="text-decoration-none text-reset">
          <div
            class="card text-white bg-color-primary"
            data-aos="fade-down-right"
            data-aos-offset="80"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <div class="card-body">
              <i class="fa-solid fa-car"></i> {{ isLanguage["Car Hire"] }}
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="mt-5">
      <Rides />
    </div>

    <ImageBanner />

    <Hotels />

    <FAQsSection />

    <PromoCards />

    <AboutSection />
  </div>
</template>

<script>
import axios from "axios";

import { useUserInfoStore } from "@/stores/userInfoStore";
import Rides from "@/components/Home/Rides.vue";
import Hotels from "@/components/Home/Hotels.vue";
import Flights from "@/components/Home/Flights.vue";
import FAQsSection from "@/components/Home/FAQsSection.vue";
import AboutSection from "@/components/Home/AboutSection.vue";
import GoogleAds from "@/components/GoogleAds.vue";
import Features from "@/components/Home/Features.vue";
import ImageBanner from "@/components/Home/ImageBanner.vue";
import Widget from "@/components/Home/Widget.vue";
import PromoCards from "@/components/PromoCards.vue";
import { toast } from "vue3-toastify";
export default {
  name: "HomePage",
  components: {
    PromoCards,
    Widget,
    ImageBanner,
    Features,
    GoogleAds,
    AboutSection,
    FAQsSection,
    Flights,
    Rides,
    Hotels,
  },
  computed: {
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
  },
  methods: {
    async fetchTokenFromQuery(query) {
      const token = query.token;

      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const urlWithoutToken = this.$route.path;
        window.history.replaceState({}, document.title, urlWithoutToken);

        try {
          const url = process.env.VUE_APP_API_URL + "/user";
          const response = await axios.get(url, {
            headers: headers,
          });
          const userData = response.data;
          userData.token = token;
          const userInfoStore = useUserInfoStore();
          userInfoStore.setUser(userData);
        } catch (error) {
          toast.error("Error on getting User Data.");
        }
      }
    },
  },

  mounted() {
    this.fetchTokenFromQuery(this.$route.query);
  },
};
</script>
