<template>
  <div
    v-for="(img, index) in bgImg"
    :key="index"
    class="main-banner-bg height-470px"
    :style="{ backgroundImage: `url('${backendUrl + img.images}')` }"
  >
    <div class="bg-overlay">
      <div class="container overflow-x-hidden pt-5">
        <div class="d-flex justify-content-between">
          <h1 class="text-white">
            {{ img.title }}
          </h1>
          <button
            v-if="isAdmin"
            type="button"
            class="btn btn-primary h-100"
            data-bs-toggle="modal"
            data-bs-target="#backgroundEditModal"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
        <div
          id="thomalex-widget"
          ref="widgetWrapper"
          class="widget overflow-y-hidden position-relative"
        >
          <!-- <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center align-self-center"
            style="height: 15rem"
          >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div> -->
          <iframe
            v-if="iframeSrc"
            :src="iframeSrc"
            style="
              border: none;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 5%;
              left: 0;
            "
            frameborder="0"
            @load="loading = false"
          ></iframe>
        </div>
      </div>
    </div>
  </div>

  <!-- Background Image Modal -->
  <div
    class="modal fade"
    id="backgroundEditModal"
    tabindex="-1"
    aria-labelledby="backgroundEditModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Change Background Image or Title</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <label for="title">Title</label>
              <input
                type="text"
                id="title"
                class="form-control mb-3"
                placeholder="Enter Title"
                v-model="title"
              />
            </div>
            <div class="col-12">
              <label for="img">Image</label>
              <input
                type="file"
                id="img"
                class="form-control"
                accept=".jpg, .jpeg, .png"
                @change="handleFileChange"
              />
              <!-- Image preview -->
              <div
                v-if="isLoading"
                class="spinner-border text-primary mt-3"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="!isLoading && imageUrl" class="position-relative">
                <img :src="imageUrl" class="mt-3" style="max-width: 20%" />
                <button
                  class="btn btn-sm btn-danger m-2 cross"
                  @click="clearImage"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateBackgroundImage"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";

export default {
  name: "Widget",
  data() {
    return {
      iframeSrc: process.env.VUE_APP_FLIGHT_WIDGET_URL,
      backendUrl: process.env.VUE_APP_IMAGE_URL,
      loading: true,
      imageUrl: null,
      isLoading: false,
      img: "",
      title: "",
    };
  },
  mounted() {
    this.getImg();
    useUserInfoStore().widgetIsLoading= true;
  },
  watch:{
    loading(newdata,olddata){
      if(newdata=== false){
        useUserInfoStore().widgetIsLoading= false;
      }
    }
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    bgImg() {
      return useUserInfoStore().bgImg;
    },
    token() {
      return useUserInfoStore().userToken();
    },
  },
  methods: {
    async getImg() {
      try {
        const data = useUserInfoStore().bgImg;
        if (data.length === 0) {
          const url =
            process.env.VUE_APP_API_URL + `/card/get/bg_img/${this.language}`;
          const response = await axios.get(url);
          useUserInfoStore().setBgImg(response.data);
          this.title = this.bgImg[0].title;
        }
      } catch (error) {
        console.error("Error on getting card:", error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.img = file;
      this.imageChanged = true;
      this.isLoading = true;
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.isLoading = false;
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.imageUrl = null;
      const input = document.getElementById("img");
      if (input) {
        input.value = "";
      }
    },
    async updateBackgroundImage() {
      if (this.token && this.bgImg) {
        const formData = new FormData();
        formData.append("id", this.bgImg[0].id);

        if (this.title !== this.bgImg[0].title) {
          formData.append("title", this.title);
        }
        if (this.imageChanged && this.img) {
          formData.append("images", this.img);
        }

        try {
          const headers = {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data",
          };
          const url = `${process.env.VUE_APP_API_URL}/card/update`;
          const response = await axios.post(url, formData, { headers });
          useUserInfoStore().updateBgImg(0, response.data);

          console.log("Updated Banner Image:", useUserInfoStore().bgImg[0]);
        } catch (error) {
          console.error("Error updating background image:", error);
        }
      }
    },
  },
};
</script>
