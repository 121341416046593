import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "@/global.css";

const app = createApp(App);

app.use(router);
const pinia = createPinia();
app.use(pinia);
app.mount("#app");
app.use(Vue3Toastify, {
  autoClose: 3000,
});
