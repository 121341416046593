<template>
  <div class="row mb-5">
    <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-4 d-flex align-items-stretch">
      <div class="d-flex justify-content-between">
        <div
          class="card border-0 shadow w-100"
          data-aos="fade-up-right"
          data-aos-offset="80"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
        >
          <div class="card-body">
            <div class="text-center">
              <i class="fa-solid fa-award fa-2xl"></i>
              <p v-html="pageContent1.body" class="mt-4"></p>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary ms-2"
            data-bs-toggle="modal"
            data-bs-target="#feature1"
            @click="openModal1"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-4 d-flex align-items-stretch">
      <div class="d-flex justify-content-between">
        <div
          class="card border-0 shadow w-100"
          data-aos="fade-up-left"
          data-aos-offset="80"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
        >
          <div class="card-body">
            <div class="text-center">
              <i class="fa-regular fa-calendar fa-2xl"></i>
              <p v-html="pageContent2.body" class="mt-4"></p>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary ms-2"
            data-bs-toggle="modal"
            data-bs-target="#feature2"
            @click="openModal2"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-md-0 mb-3 mb-md-0 mb-4 d-flex align-items-stretch">
      <div class="d-flex justify-content-between">
        <div
          class="card border-0 shadow w-100"
          data-aos="fade-up-right"
          data-aos-offset="80"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
        >
          <div class="card-body">
            <div class="text-center">
              <i class="fa-solid fa-headset fa-2xl"></i>
              <p v-html="pageContent3.body" class="mt-4"></p>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary ms-2"
            data-bs-toggle="modal"
            data-bs-target="#feature3"
            @click="openModal3"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="feature1"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent1.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body height-200px">
          <ckeditor
            :editor="editor"
            v-model="editedContent1"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent1.id, pageContent1.title, editedContent1)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="feature2"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent2.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body height-200px">
          <ckeditor
            :editor="editor"
            v-model="editedContent2"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent2.id, pageContent2.title, editedContent2)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="feature3"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent3.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body height-200px">
          <ckeditor
            :editor="editor"
            v-model="editedContent3"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent3.id, pageContent3.title, editedContent3)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";

export default {
  name: "Features",
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContent1: "",
      editedContent2: "",
      editedContent3: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    pageContent1() {
      return useUserInfoStore().feature1;
    },
    pageContent2() {
      return useUserInfoStore().feature2;
    },
    pageContent3() {
      return useUserInfoStore().feature3;
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
  },
  methods: {
    openModal1() {
      this.editedContent1 = this.pageContent1.body;
    },
    openModal2() {
      this.editedContent2 = this.pageContent2.body;
    },
    openModal3() {
      this.editedContent3 = this.pageContent3.body;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating content:", error);
        });
    },
  },
};
</script>
