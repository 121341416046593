<template>
  <div
    class="container mt-3 mb-5 min-vh-70 d-flex justify-content-center align-items-center"
  >
    <div class="w-100">
      <div class="card border-0 shadow p-5">
        <div class="card-body">
          <h1 class="text-center mb-4">Reset Password</h1>
          <form @submit.prevent="resetPassword">
            <div class="form-floating">
              <input
                :type="passwordVisible ? 'text' : 'password'"
                class="form-control border-0 border-bottom"
                id="newPassword"
                placeholder="Password"
                v-model="password"
                @blur="passwordBlurred = true"
                required
              />
              <label for="newPassword">New Password</label>
              <i
                @click="passwordVisible = !passwordVisible"
                class="eyeButton cursor-pointer"
                :class="
                  passwordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                "
              ></i>
            </div>
            <div
              v-if="passwordBlurred && password.length < 8"
              class="text-danger"
            >
              Password must be at least 8 characters long.
            </div>
            <div class="form-floating mt-3">
              <input
                :type="confirmPasswordVisible ? 'text' : 'password'"
                class="form-control border-0 border-bottom"
                id="confirmPassword"
                placeholder="Confirm Password"
                v-model="password_confirmation"
                @blur="confirmPasswordBlurred = true"
                required
              />
              <label for="confirmPassword">Confirm New Password</label>
              <i
                @click="confirmPasswordVisible = !confirmPasswordVisible"
                class="eyeButton cursor-pointer"
                :class="
                  confirmPasswordVisible
                    ? 'fa-solid fa-eye-slash'
                    : 'fa-solid fa-eye'
                "
              ></i>
              <div
                v-if="confirmPasswordBlurred && !passwordsMatch"
                class="text-danger"
              >
                Passwords do not match.
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                type="submit"
                class="btn btn-outline-primary ms-3"
                :disabled="loader || !passwordsMatch || password.length < 8"
              >
                <span v-if="loader">
                  <span
                    class="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Reset Password</span>
                </span>
                <span v-else>Reset Password</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

export default {
  name: "ResetPassword",
  data() {
    return {
      token: null,
      email: null,
      loader: false,
      password: "",
      password_confirmation: "",
      passwordVisible: false,
      confirmPasswordVisible: false,
      passwordBlurred: false,
      confirmPasswordBlurred: false,
    };
  },
  created() {
    // Capture token and email from the URL
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;

    // Remove token and email from the URL
    // this.$router.replace({ path: "/forget-password", query: {} });
  },
  computed: {
    passwordsMatch() {
      return this.password === this.password_confirmation;
    },
  },
  methods: {
    async resetPassword() {
      this.loader = true;
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: this.token,
              email: this.email,
              password: this.password,
            }),
          }
        );
        if (response.ok) {
          const result = await response.json();
          toast.success(result.message);
          this.$router.push("/home");
          this.loader = false;
        } else {
          const error = await response.json();
          toast.error(
            error.message || "Failed to reset password, please try again."
          );
          this.loader = false;
        }
      } catch (error) {
        toast.error(error.message || "Server Error.");
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>
