<template>
  <div class="modal fade" id="adsModal" tabindex="-1" aria-labelledby="adsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="adsModalLabel">
            {{ actionType === "edit" ? "Edit Card" : "Add Card" }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <label for="title">Title</label>
                <input type="text" id="title" class="form-control mb-3" placeholder="Enter Title"
                       v-model="data.title"/>
              </div>
              <div class="col-md-6">
                <label for="desc">heading 1</label>
                <input type="text" id="heading 1" class="form-control mb-3" placeholder="Enter Description"
                       v-model="data.detail1"/>
              </div>
              <div class="col-md-6">
                <label for="city">heading 2</label>
                <input type="text" class="form-control mb-3" placeholder="Enter heading 2" v-model="data.detail2"/>
              </div>
              <div class="col-md-6">
                <label for="city">heading 3</label>
                <input type="text" class="form-control mb-3" placeholder="Enter heading 3" v-model="data.detail3"/>
              </div>
              <div class="col-md-6 align-self-center">
                <label for="status">Status: </label>
                <div class="form-check form-check-inline ms-3" id="status">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                         value="active" v-model="data.status"/>
                  <label class="form-check-label" for="inlineRadio1">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                         value="inactive" v-model="data.status"/>
                  <label class="form-check-label" for="inlineRadio2">
                    Inactive
                  </label>
                </div>
              </div>
              <div class="col-12">
                <label for="img">Image</label>
                <input type="file" id="img" class="form-control" accept=".jpg, .jpeg, .png"
                       @change="handleFileChange"/>
                <!-- Image preview -->
                <div v-if="isLoading" class="spinner-border text-primary mt-3" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div v-if="!isLoading && imageUrl" class="position-relative">
                  <img :src="imageUrl" class="mt-3" style="max-width: 20%"/>
                  <!-- Cross button -->
                  <button class="btn btn-sm btn-danger m-2 cross" @click="clearImage">
                    &times;
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="saveChanges">
            {{ actionType === "edit" ? "Save Changes" : "Add Card" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {useUserInfoStore} from "@/stores/userInfoStore";
import {toast} from "vue3-toastify";

export default {
  name: "adsModal",
  props: {
    selectedCard: {
      type: Array,
    },
    selectedCardIndex: {
      type: Number,
    },
    actionType: {
      type: String,
    },
    cardFor: {
      type: String,
    },
  },
  data() {
    return {
      imageUrl: null,
      isLoading: false,
      data: {
        title: "",
        detail1: "",
        detail2: "",
        detail3: "",
        status: "",
        img: "",
      },
    };
  },
  watch: {
    actionType() {
      this.clearData();
      this.fetchDataFromCard();
    },
    selectedCard(after, before) {
      this.fetchDataFromCard();
      console.log(after, before);
    },
  },
  computed: {
    token() {
      return useUserInfoStore().userToken();
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    }
  },
  methods: {
    saveChanges() {
      if (this.actionType === "edit") {
        this.updateCard();
      } else {
        this.addCard();
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.img = file;
      this.imageChanged = true;
      this.isLoading = true;
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.isLoading = false;
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.imageUrl = null;
      const input = document.getElementById("img");
      if (input) {
        input.value = "";
      }
    },
    clearData() {
      this.data.title = "";
      this.data.detail1 = "";
      this.data.detail2 = "";
      this.data.detail3 = "";
      this.data.status = "";
      this.data.img = "";
    },
    fetchDataFromCard() {
      if (this.actionType === 'edit') {
        this.data = {
          title: this.selectedCard.title || "",
          detail1: this.selectedCard.detail1 || "",
          detail2: this.selectedCard.detail2 || "",
          detail3: this.selectedCard.detail3 || "",
          status: this.selectedCard.status || "",
          img: null,
        };
        this.originalData = {...this.data};
      }
    },

    async updateCard() {
      if (this.token && this.selectedCard) {
        const formData = new FormData();
        formData.append("id", this.selectedCard.id);

        for (const key in this.data) {
          if (this.data[key] !== this.originalData[key]) {
            formData.append(key, this.data[key]);
          }
        }
        if (this.imageChanged && this.img) {
          formData.append("images", this.img);
        }

        try {
          const headers = {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
          };
          const url = `${process.env.VUE_APP_API_URL}/ad/update`;
          const response = await axios.post(url, formData, {headers});
          toast.success(this.isLanguage['Card Updated Successfully']);

          if (this.cardFor === 'flightAds') {
            useUserInfoStore().updateFlightAds(this.selectedCardIndex, response.data);
          }
        } catch (error) {
          toast.error(this.isLanguage['Error updating card']);
        }
      }
    },
  },
};
</script>

<style scoped>
.cross {
  position: absolute;
  top: 0%;
  left: 16%;
}
</style>
