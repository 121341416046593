<template>
  <div class="row" lazy="loading">
    <div class="d-flex">
      <p v-html="pageContent5.body"></p>
      <div>
        <button
          type="button"
          v-if="isAdmin"
          class="btn btn-primary ms-4"
          data-bs-toggle="modal"
          data-bs-target="#adTitle1"
          @click="openModal5"
        >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      </div>
    </div>
    <div
      class="col-md-4 mb-md-0 mb-4"
      v-for="(Ads, index) in flightAds"
      :key="index"
    >
      <div>
        <div
          class="card text-bg-dark"
          data-aos="fade-right"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out"
        >
          <div class="image-overlay">
            <img :src="imageurl + Ads.images" class="card-img" alt="img" />
          </div>
          <div
            class="card-img-overlay p-0 z-3 d-flex justify-content-between align-self-end"
          >
            <div>
              <h4 class="card-title fw-bolder ms-4">{{ Ads.title }}</h4>
              <h6 class="fw-bold ms-3">{{ Ads.detail2 }}</h6>
            </div>
            <div class="align-self-end">
              <h4 class="fw-bold mb-2 me-2">{{ Ads.detail3 }}</h4>
            </div>
          </div>
        </div>
        <button
          v-if="isAdmin"
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#adsModal"
          @click.prevent="editCard(Ads, 'edit', index)"
          @click.stop
        >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="row py-5" lazy="loading">
    <div class="d-flex">
      <p v-html="pageContent6.body"></p>
      <div>
        <button
          type="button"
          v-if="isAdmin"
          class="btn btn-primary ms-4"
          data-bs-toggle="modal"
          data-bs-target="#adTitle2"
          @click="openModal6"
        >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      </div>
    </div>
    <div
      class="col-md-3"
      data-aos="fade-right"
      data-aos-offset="50"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-in-out"
    >
      <div class="row">
        <div class="col-2 d-flex justify-content-end mt-3">
          <i class="fa-solid fa-check fa-lg" style="color: #149f75"></i>
        </div>
        <div class="col-8">
          <p v-html="pageContent1.body"></p>
        </div>
        <div class="col-2">
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#ad1"
            @click="openModal1"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-md-3"
      data-aos="fade-right"
      data-aos-offset="50"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-in-out"
    >
      <div class="row">
        <div class="col-2 d-flex justify-content-end mt-3">
          <i class="fa-solid fa-check fa-lg" style="color: #149f75"></i>
        </div>
        <div class="col-8">
          <p v-html="pageContent2.body"></p>
        </div>
        <div class="col-2">
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#ad2"
            @click="openModal2"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-md-3"
      data-aos="fade-right"
      data-aos-offset="50"
      data-aos-delay="50"
      data-aos-duration="2000"
      data-aos-easing="ease-in-out"
    >
      <div class="row">
        <div class="col-2 d-flex justify-content-end mt-3">
          <i class="fa-solid fa-check fa-lg" style="color: #149f75"></i>
        </div>
        <div class="col-8">
          <p v-html="pageContent3.body"></p>
        </div>
        <div class="col-2">
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#ad3"
            @click="openModal3"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-3" data-aos="fade-right" data-aos-duration="2000">
      <div class="row">
        <div class="col-2 d-flex justify-content-end mt-3">
          <i class="fa-solid fa-check fa-lg" style="color: #149f75"></i>
        </div>
        <div class="col-8">
          <p v-html="pageContent4.body"></p>
        </div>
        <div class="col-2">
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#ad4"
            @click="openModal4"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <AdsModal
    :selectedCard="selectedCard"
    :cardFor="cardFor"
    :actionType="actionType"
    :selectedCardIndex="selectedCardIndex"
  />

  <!-- Modal -->
  <div
    class="modal fade"
    id="ad1"
    tabindex="-1"
    aria-labelledby="ad1label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent1.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent1"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent1.id, pageContent1.title, editedContent1)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="ad2"
    tabindex="-1"
    aria-labelledby="ad1label2"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent2.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent2"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent2.id, pageContent2.title, editedContent2)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="ad3"
    tabindex="-1"
    aria-labelledby="ad1label3"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent3.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent3"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent3.id, pageContent3.title, editedContent3)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="ad4"
    tabindex="-1"
    aria-labelledby="ad1label4"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent4.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent4"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent4.id, pageContent4.title, editedContent4)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="adTitle1"
    tabindex="-1"
    aria-labelledby="adTitle1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent5.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent5"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent5.id, pageContent5.title, editedContent5)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="adTitle2"
    tabindex="-1"
    aria-labelledby="adTitle2"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContent6.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="editedContent6"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(pageContent6.id, pageContent6.title, editedContent6)
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { toast } from "vue3-toastify";
import AdsModal from "@/components/AdsModal.vue";
import { useUserInfoStore } from "@/stores/userInfoStore";
import axios from "axios";
export default {
  name: "AdSection",
  components: { AdsModal, ckeditor: CKEditor.component },
  data() {
    return {
      imageurl: process.env.VUE_APP_IMAGE_URL,
      selectedCard: [],
      selectedCardIndex: "",
      actionType: "",
      cardFor: "flightAds",
      showButtons: true,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContent1: "",
      editedContent2: "",
      editedContent3: "",
      editedContent4: "",
      editedContent5: "",
      editedContent6: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    flightAds() {
      return useUserInfoStore().flightAds;
    },
    pageContent1() {
      return useUserInfoStore().adContent1;
    },
    pageContent2() {
      return useUserInfoStore().adContent2;
    },
    pageContent3() {
      return useUserInfoStore().adContent3;
    },
    pageContent4() {
      return useUserInfoStore().adContent4;
    },
    pageContent5() {
      return useUserInfoStore().adTitle1;
    },
    pageContent6() {
      return useUserInfoStore().adTitle2;
    },
  },
  mounted() {
    this.getcards();
  },
  methods: {
    editCard(data, actionType, index) {
      this.selectedCard = data;
      this.actionType = actionType;
      this.selectedCardIndex = index;
    },
    async getcards() {
      try {
        const data = useUserInfoStore().flightAds;
        if (data.length === 0) {
          useUserInfoStore().widgetIsLoading = true;
          const url =
            process.env.VUE_APP_API_URL + `/ad/get/flightAds/${this.language}`;
          const response = await axios.get(url);
          useUserInfoStore().setFlightAds(response.data);
          useUserInfoStore().widgetIsLoading = false;
        }
      } catch (error) {
        toast.error("Error on getting card");
      }
    },
    openModal1() {
      this.editedContent1 = this.pageContent1.body;
    },
    openModal2() {
      this.editedContent2 = this.pageContent2.body;
    },
    openModal3() {
      this.editedContent3 = this.pageContent3.body;
    },
    openModal4() {
      this.editedContent4 = this.pageContent4.body;
    },
    openModal5() {
      this.editedContent5 = this.pageContent5.body;
    },
    openModal6() {
      this.editedContent6 = this.pageContent6.body;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully!");
        })
        .catch(() => {
          toast.error("Error updating content");
        });
    },
  },
};
</script>

<style>
.card-img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 786px) {
  .card-img {
    width: 350px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
