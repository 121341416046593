<template>
    <div class="loading-screen d-flex justify-content-center align-items-center">
      <img src="../../public/logo.jpg" alt="Loading" class="logo-spin">
    </div>
  </template>
  
  <script>
  export default {
    name: "LoadingScreen",
  }
  </script>
  
  <style scoped>
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(85, 81, 81, 0.966);
    z-index: 9999;
  }
  
  .logo-spin {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }
  
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateY(-20px);
    animation-timing-function: ease-out;
  }
}

.logo-spin {
  width: 100px;
  height: 100px;
  animation: bounce 2s infinite;
}


  </style>
  