<template>
  <div class="row py-5">
    <div class="col-md-4 d-flex align-items-stretch">
      <div class="ms-3 d-flex justify-content-between">
        <p v-html="pageContentCard1.body"></p>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#editModalCard1"
            @click="openModalCard1"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 d-flex align-items-stretch">
      <div class="ms-3 d-flex justify-content-between">
        <div>
          <p v-html="pageContentCard2.body"></p>
          <div class="d-flex gap-3 mt-3 mb-md-0 mb-4">
            <a :href="pageContentLink1.body">
              <i class="fa-brands fa-facebook fa-lg mt-4"></i>
            </a>
            <i
              v-if="isAdmin"
              data-bs-toggle="modal"
              data-bs-target="#editModalLink1"
              class="fa-solid fa-pen-to-square cursor-pointer"
              @click="openModalLink1"
            ></i>
            <a :href="pageContentLink2.body">
              <i class="fa-brands fa-x-twitter fa-lg mt-4"></i>
            </a>
            <i
              v-if="isAdmin"
              data-bs-toggle="modal"
              data-bs-target="#editModalLink2"
              class="fa-solid fa-pen-to-square cursor-pointer"
              @click="openModalLink2"
            ></i>
            <a :href="pageContentLink3.body">
              <i class="fa-brands fa-linkedin fa-lg mt-4"></i>
            </a>
            <i
              v-if="isAdmin"
              data-bs-toggle="modal"
              data-bs-target="#editModalLink3"
              class="fa-solid fa-pen-to-square cursor-pointer"
              @click="openModalLink3"
            ></i>
            <a :href="pageContentLink4.body">
              <i class="fa-brands fa-instagram fa-lg mt-4"></i>
            </a>
            <i
              v-if="isAdmin"
              data-bs-toggle="modal"
              data-bs-target="#editModalLink4"
              class="fa-solid fa-pen-to-square cursor-pointer"
              @click="openModalLink4"
            ></i>
            <a :href="pageContentLink5.body">
              <i class="fa-brands fa-youtube fa-lg mt-4"></i>
            </a>
            <i
              v-if="isAdmin"
              data-bs-toggle="modal"
              data-bs-target="#editModalLink5"
              class="fa-solid fa-pen-to-square cursor-pointer"
              @click="openModalLink5"
            ></i>
          </div>
        </div>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#editModalCard2"
            @click="openModalCard2"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 d-flex align-items-stretch">
      <div class="ms-3 d-flex justify-content-between">
        <p v-html="pageContentCard3.body"></p>
        <div>
          <button
            type="button"
            v-if="isAdmin"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#editModalCard3"
            @click="openModalCard3"
          >
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="editModalCard1"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content min-vh-40">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentCard1.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            v-model="editedContentCard1"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentCard1.id,
                pageContentCard1.title,
                editedContentCard1
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalCard2"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content min-vh-40">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentCard2.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            v-model="editedContentCard2"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentCard2.id,
                pageContentCard2.title,
                editedContentCard2
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalCard3"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content min-vh-40">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentCard3.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ckeditor
            :editor="editor"
            v-model="editedContentCard3"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentCard3.id,
                pageContentCard3.title,
                editedContentCard3
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalLink1"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentLink1.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control"
            v-model="editedContentLink1"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentLink1.id,
                pageContentLink1.title,
                editedContentLink1
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalLink2"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentLink2.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control"
            v-model="editedContentLink2"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentLink2.id,
                pageContentLink2.title,
                editedContentLink2
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalLink3"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentLink3.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control"
            v-model="editedContentLink3"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentLink3.id,
                pageContentLink3.title,
                editedContentLink3
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalLink4"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentLink4.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control"
            v-model="editedContentLink4"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentLink4.id,
                pageContentLink4.title,
                editedContentLink4
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editModalLink5"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ pageContentLink5.title }}</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control"
            v-model="editedContentLink5"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              saveChanges(
                pageContentLink5.id,
                pageContentLink5.title,
                editedContentLink5
              )
            "
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import { useUserInfoStore } from "@/stores/userInfoStore";
import { toast } from "vue3-toastify";

export default {
  name: "AboutSection",
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editedContentCard1: "",
      editedContentCard2: "",
      editedContentCard3: "",
      editedContentLink1: "",
      editedContentLink2: "",
      editedContentLink3: "",
      editedContentLink4: "",
      editedContentLink5: "",
      defaultLanguage: "en",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    pageContentCard1() {
      return useUserInfoStore().aboutCard1;
    },
    pageContentCard2() {
      return useUserInfoStore().aboutCard2;
    },
    pageContentCard3() {
      return useUserInfoStore().aboutCard3;
    },
    pageContentLink1() {
      return useUserInfoStore().aboutLink1;
    },
    pageContentLink2() {
      return useUserInfoStore().aboutLink2;
    },
    pageContentLink3() {
      return useUserInfoStore().aboutLink3;
    },
    pageContentLink4() {
      return useUserInfoStore().aboutLink4;
    },
    pageContentLink5() {
      return useUserInfoStore().aboutLink5;
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
  },
  methods: {
    openModalCard1() {
      this.editedContentCard1 = this.pageContentCard1.body;
    },
    openModalCard2() {
      this.editedContentCard2 = this.pageContentCard2.body;
    },
    openModalCard3() {
      this.editedContentCard3 = this.pageContentCard3.body;
    },
    openModalLink1() {
      this.editedContentLink1 = this.pageContentLink1.body;
    },
    openModalLink2() {
      this.editedContentLink2 = this.pageContentLink2.body;
    },
    openModalLink3() {
      this.editedContentLink3 = this.pageContentLink3.body;
    },
    openModalLink4() {
      this.editedContentLink4 = this.pageContentLink4.body;
    },
    openModalLink5() {
      this.editedContentLink5 = this.pageContentLink5.body;
    },
    saveChanges(id, title, content) {
      const postData = {
        id: id,
        title: title,
        body: content,
      };

      const userToken = useUserInfoStore().userToken();
      axios
        .post(process.env.VUE_APP_API_URL + "/page-contents/update", postData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          useUserInfoStore().updateContent(response.data, id - 1);
          toast.success("Content updated successfully:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating content:", error);
        });
    },
  },
};
</script>

<style scoped>
.min-vh-40 {
  min-height: 40vh;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
