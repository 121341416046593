<template>
  <nav class="navbar navbar-expand-lg" style="background-color: #224178">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="../../public/logo.jpg" alt="logo" width="100" />
      </router-link>
      <div class="nav-items-container me-auto mb-2 mb-lg-0">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              to="/home"
            >
              {{ isLanguage["Home"] }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              to="/flights"
            >
              {{ isLanguage["Flights Deals"] }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              to="/hotels"
            >
              {{ isLanguage["Hotels Deals"] }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              to="/rides"
            >
              {{ isLanguage["Rides Deals"] }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="nav-items-container">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link text-white dropdown-toggle custom-dropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ isLanguage["Support"] }}
            </a>
            <ul class="dropdown-menu border-0 shadow">
              <li>
                <a class="dropdown-item" href="tel:+1878.309.4371">
                  <i class="fa-solid fa-phone me-2"></i> +1878.309.4371
                </a>
              </li>
              <li>
                <router-link class="dropdown-item" to="/help">
                  <i class="fa-solid fa-headset fa-lg me-2"></i>
                  {{ isLanguage["Contact us"] }}
                </router-link>
              </li>
            </ul>
          </li>
          <!--          <li class="nav-item dropdown" :class="{ 'is-invalid': errorMessage }">-->
          <!--            <a-->
          <!--              class="nav-link text-white dropdown-toggle custom-dropdown"-->
          <!--              href="#"-->
          <!--              role="button"-->
          <!--              data-bs-toggle="dropdown"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              {{ selectedAirport || "Select Airport" }}-->
          <!--            </a>-->
          <!--            <ul class="dropdown-menu border-0 shadow">-->
          <!--              <li class="p-2">-->
          <!--                <input-->
          <!--                  type="text"-->
          <!--                  class="form-control"-->
          <!--                  v-model="searchTerm"-->
          <!--                  @input="filterAirports"-->
          <!--                  placeholder="Search airport..."-->
          <!--                />-->
          <!--              </li>-->
          <!--              <li v-for="airport in filteredAirports" :key="airport.code">-->
          <!--                <a-->
          <!--                  class="dropdown-item"-->
          <!--                  href="#"-->
          <!--                  @click.prevent="selectAirport(airport.code)"-->
          <!--                >-->
          <!--                  {{ airport.code }} - {{ airport.name }}-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            <div v-if="errorMessage" class="invalid-feedback">-->
          <!--              {{ errorMessage }}-->
          <!--            </div>-->
          <!--          </li>-->
          <li class="nav-item" v-if="selectedCountry">
            <a class="nav-link text-white" href="#">
              <span v-html="getCountryFlag(selectedCountry)"></span>
            </a>
          </li>
          <li class="nav-item dropdown" v-if="!isAdmin">
            <a
              class="nav-link text-white dropdown-toggle custom-dropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ selectedLanguage }}
            </a>
            <ul class="dropdown-menu border-0 shadow">
              <li v-for="language in languages" :key="language">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="setselectedLanguage(language)"
                >
                  {{ language }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div v-if="isLoggedIn" class="nav-item dropdown ms-2 align-self-center">
          <a
            class="nav-link text-white dropdown-toggle custom-dropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              v-if="userdata.image"
              :src="backendUrl + userdata.image"
              alt=""
              class="user-image"
            />
            <img
              v-else
              src="/avatar.jpg"
              alt="Default Avatar"
              class="user-image"
            />
            {{ userdata.first_name }}
            {{ userdata.last_name === null ? "" : userdata.last_name }}
          </a>
          <ul class="dropdown-menu border-0 shadow">
            <li>
              <router-link class="dropdown-item" to="/information">
                {{ isLanguage["My Information"] }}
              </router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="#" @click="logout">
                {{ isLanguage["Logout"] }}
              </router-link>
            </li>
          </ul>
        </div>
        <button
          v-else
          type="button"
          class="btn btn-outline-primary ms-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <i class="fa-regular fa-user me-2"></i> {{ isLanguage["Log in"] }}
        </button>
      </div>
      <!--      <button-->
      <!--        v-if="showAirportModal"-->
      <!--        type="button"-->
      <!--        class="btn btn-outline-primary ms-3"-->
      <!--        data-bs-toggle="modal"-->
      <!--        data-bs-target="#airportModal"-->
      <!--      >-->
      <!--        Open-->
      <!--      </button>-->
      <button
        class="btn offcanvas-button"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <i class="fa-solid fa-bars fa-2xl text-white"></i>
      </button>
    </div>
  </nav>

  <div
    class="offcanvas offcanvas-start custom-bg"
    tabindex="-1"
    id="offcanvasNavbar"
    aria-labelledby="offcanvasNavbarLabel"
  >
    <div class="offcanvas-header pt-5">
      <div v-if="isLoggedIn" class="nav-item dropdown mt-2 align-self-center">
        <a
          class="nav-link dropdown-toggle custom-dropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            v-if="userdata.image"
            :src="backendUrl + userdata.image"
            alt=""
            class="user-image"
          />
          <img
            v-else
            src="/avatar.jpg"
            alt="Default Avatar"
            class="user-image"
          />
          {{ userdata.first_name }}
          {{ userdata.last_name === null ? "" : userdata.last_name }}
        </a>
        <ul class="dropdown-menu border-0 shadow">
          <li>
            <a class="dropdown-item" href="/information">
              {{ isLanguage["My Information"] }}
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="logout">
              {{ isLanguage["Logout"] }}
            </a>
          </li>
        </ul>
      </div>
      <button
        v-else
        type="button"
        class="btn btn-outline-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i class="fa-regular fa-user me-2"></i> {{ isLanguage["Log in"] }}
      </button>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav text-center mt-4">
        <div class="d-flex justify-content-center gap-4">
          <!--          <li-->
          <!--            class="nav-item dropdown-center"-->
          <!--            :class="{ 'is-invalid': errorMessage }"-->
          <!--          >-->
          <!--            <a-->
          <!--              class="nav-link dropdown-toggle custom-dropdown"-->
          <!--              href="#"-->
          <!--              role="button"-->
          <!--              data-bs-toggle="dropdown"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              {{ selectedAirport || "Select Airport" }}-->
          <!--            </a>-->
          <!--            <ul class="dropdown-menu border-0 shadow">-->
          <!--              <li class="p-2">-->
          <!--                <input-->
          <!--                  type="text"-->
          <!--                  class="form-control"-->
          <!--                  v-model="searchTerm"-->
          <!--                  @input="filterAirports"-->
          <!--                  placeholder="Search airport..."-->
          <!--                />-->
          <!--              </li>-->
          <!--              <li v-for="airport in filteredAirports" :key="airport.code">-->
          <!--                <a-->
          <!--                  class="dropdown-item"-->
          <!--                  href="#"-->
          <!--                  @click.prevent="selectAirport(airport.code)"-->
          <!--                >-->
          <!--                  {{ airport.code }} - {{ airport.name }}-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            <div v-if="errorMessage" class="invalid-feedback">-->
          <!--              {{ errorMessage }}-->
          <!--            </div>-->
          <!--          </li>-->
          <li class="nav-item" v-if="selectedCountry">
            <a class="nav-link text-white" href="#">
              <span v-html="getCountryFlag(selectedCountry)"></span>
            </a>
          </li>
        </div>
        <hr />
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="/">
            {{ isLanguage["Home"] }}
          </a>
        </li>
        <hr />
        <li class="nav-item">
          <a class="nav-link" href="/flights">
            {{ isLanguage["Flights Deals"] }}
          </a>
        </li>
        <hr />
        <li class="nav-item">
          <a class="nav-link" href="/hotels">
            {{ isLanguage["Hotels Deals"] }}
          </a>
        </li>
        <hr />
        <li class="nav-item">
          <a class="nav-link" href="/rides">
            {{ isLanguage["Rides Deals"] }}
          </a>
        </li>
        <hr />
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle custom-dropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ isLanguage["Support"] }}
          </a>
          <ul class="dropdown-menu border-0 shadow">
            <li>
              <a class="dropdown-item" href="tel:+1878.309.4371">
                <i class="fa-solid fa-phone me-2"></i> +1878.309.4371
              </a>
            </li>
            <li>
              <router-link class="dropdown-item" to="/help">
                <i class="fa-solid fa-headset fa-lg me-2"></i>
                {{ isLanguage["Contact us"] }}
              </router-link>
            </li>
          </ul>
        </li>
        <hr />
        <li class="nav-item dropdown" v-if="!isAdmin">
          <a
            class="nav-link dropdown-toggle custom-dropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ selectedLanguage }}
          </a>
          <ul class="dropdown-menu border-0 shadow">
            <li v-for="language in languages" :key="language">
              <a
                class="dropdown-item"
                href="#"
                @click="setselectedLanguage(language)"
              >
                {{ language }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content rounded-3">
        <div class="modal-body p-0">
          <div class="row">
            <div
              class="col-md-6 ps-3 pt-5 pb-3 pe-4 position-relative"
              v-if="!forgotPassword"
            >
              <div
                class="cross-icon-container cursor-pointer d-md-none d-block"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="clearData"
              >
                <div class="cross-line"></div>
                <div class="cross-line"></div>
              </div>
              <div class="text-center">
                <h2 v-if="formType === 'login'">{{ isLanguage["Log in"] }}</h2>
                <h2 v-else-if="showRegisterForm">
                  {{ isLanguage["Register"] }}
                </h2>
              </div>
              <div
                class="d-flex justify-content-center gap-3 mt-4 mb-3 ms-3"
                v-if="showRegisterForm"
              >
                <button
                  class="btn btn-outline-dark rounded-0 px-4"
                  @click="signInWithGoogle"
                >
                  <i class="fa-brands fa-google fa-lg me-2"></i> Google
                </button>
                <button
                  class="btn btn-outline-dark rounded-0 px-4"
                  @click="signInWithFacebook"
                >
                  <i class="fa-brands fa-facebook fa-lg me-2"></i> Facebook
                </button>
              </div>
              <div class="row" v-if="showRegisterForm">
                <div class="col-2 ms-3"></div>
                <div class="col-3">
                  <hr />
                </div>
                <div class="col-1 me-2">{{ isLanguage["or"] }}</div>
                <div class="col-3">
                  <hr />
                </div>
                <div class="col-2"></div>
              </div>

              <!--Login Form-->
              <form
                class="mt-3 mb-3 ps-3 pe-2 py-2"
                v-if="formType === 'login'"
                @submit.prevent="login"
              >
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control border-0 border-bottom"
                    id="loginEmail"
                    placeholder="name@example.com"
                    v-model="loginEmail"
                    required
                  />
                  <label for="loginEmail">
                    {{ isLanguage["Email Address"] }}
                  </label>
                </div>
                <div class="form-floating">
                  <input
                    :type="passwordVisible ? 'text' : 'password'"
                    class="form-control border-0 border-bottom"
                    id="loginPassword"
                    placeholder="Password"
                    v-model="loginPassword"
                    required
                  />
                  <label for="loginPassword">
                    {{ isLanguage["Password"] }}
                  </label>
                  <i
                    @click="passwordVisible = !passwordVisible"
                    class="eyeButton cursor-pointer"
                    :class="
                      passwordVisible
                        ? 'fa-solid fa-eye-slash'
                        : 'fa-solid fa-eye'
                    "
                  ></i>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label class="form-check-label" for="gridCheck">
                      {{ isLanguage["Remember me"] }}
                    </label>
                  </div>
                  <a href="#" @click="toggleForgotPassword" class="ms-2">
                    {{ isLanguage["Forgot Password"] }}?
                  </a>
                </div>
                <div class="text-center mt-3">
                  {{ isLanguage["Don't have an account?"] }}
                  <a href="#" @click="toggleFormType">
                    {{ isLanguage["Register"] }}</a
                  >
                </div>
                <div class="text-center mt-4">
                  <button
                    type="submit"
                    class="btn btn-outline-primary ms-3"
                    :disabled="!loginFormValid && loader"
                  >
                    <span v-if="loader">
                      <span
                        class="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>
                        <i class="fa-solid fa-arrow-right-to-bracket me-2"></i>
                        {{ isLanguage["Log in"] }}
                      </span>
                    </span>
                    <span v-else>
                      <i class="fa-solid fa-arrow-right-to-bracket me-2"></i>
                      {{ isLanguage["Log in"] }}
                    </span>
                  </button>
                </div>
              </form>

              <!--Register Form-->
              <div v-else>
                <form
                  v-if="showRegisterForm"
                  class="row mt-3 mb-3 ps-3 pe-2 py-2"
                  @submit.prevent="register"
                >
                  <div class="col-md-6 form-floating mb-3">
                    <input
                      type="text"
                      class="form-control border-0 border-bottom"
                      id="registerFirstName"
                      placeholder="Your First Name"
                      v-model="registerFirstName"
                      required
                    />
                    <label for="registerFirstName">
                      {{ isLanguage["First Name"] }}
                    </label>
                  </div>
                  <div class="col-md-6 form-floating mb-3">
                    <input
                      type="text"
                      class="form-control border-0 border-bottom"
                      id="registerLastName"
                      placeholder="Your Last Name"
                      v-model="registerLastName"
                      required
                    />
                    <label for="registerLastName">
                      {{ isLanguage["Last Name"] }}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      type="email"
                      class="form-control border-0 border-bottom"
                      id="registerEmail"
                      placeholder="name@example.com"
                      v-model="registerEmail"
                      required
                    />
                    <label for="registerEmail">
                      {{ isLanguage["Email Address"] }}
                    </label>
                  </div>
                  <div class="form-floating">
                    <input
                      :type="passwordVisible ? 'text' : 'password'"
                      class="form-control border-0 border-bottom"
                      id="registerPassword"
                      placeholder="Password"
                      v-model="registerPassword"
                      @blur="passwordBlurred = true"
                      required
                    />
                    <label for="registerPassword">
                      {{ isLanguage["Password"] }}
                    </label>
                    <i
                      @click="passwordVisible = !passwordVisible"
                      class="eyeButton cursor-pointer me-3"
                      :class="
                        passwordVisible
                          ? 'fa-solid fa-eye-slash'
                          : 'fa-solid fa-eye'
                      "
                    ></i>
                  </div>
                  <div
                    v-if="registerSubmitted && registerPassword.length < 8"
                    class="text-danger"
                  >
                    {{
                      isLanguage["Password must be at least 8 characters long."]
                    }}
                  </div>
                  <div class="form-floating mt-3">
                    <input
                      :type="confirmPasswordVisible ? 'text' : 'password'"
                      class="form-control border-0 border-bottom"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      v-model="confirmPassword"
                      @blur="confirmPasswordBlurred = true"
                      required
                    />
                    <label for="confirmPassword">
                      {{ isLanguage["Confirm Password"] }}
                    </label>
                    <i
                      @click="confirmPasswordVisible = !confirmPasswordVisible"
                      class="eyeButton cursor-pointer me-3"
                      :class="
                        confirmPasswordVisible
                          ? 'fa-solid fa-eye-slash'
                          : 'fa-solid fa-eye'
                      "
                    ></i>
                    <div
                      v-if="
                        registerSubmitted &&
                        confirmPasswordBlurred &&
                        !passwordsMatch
                      "
                      class="text-danger"
                    >
                      {{ isLanguage["Passwords do not match."] }}
                    </div>
                  </div>
                  <div class="text-end mt-3">
                    {{ isLanguage["Already have an account ?"] }}
                    <a href="#" @click="toggleFormType">
                      {{ isLanguage["Log in"] }}
                    </a>
                  </div>
                  <div class="text-center mt-4">
                    <button
                      type="submit"
                      class="btn btn-outline-primary ms-3"
                      :disabled="!registerFormValid && loader"
                    >
                      <span v-if="loader">
                        <span
                          class="spinner-border spinner-border-sm me-3"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span>
                          <i
                            class="fa-solid fa-arrow-right-to-bracket me-2"
                          ></i>
                          {{ isLanguage["Register"] }}
                        </span>
                      </span>
                      <span v-else>
                        <i class="fa-solid fa-arrow-right-to-bracket me-2"></i>
                        {{ isLanguage["Register"] }}
                      </span>
                    </button>
                  </div>
                </form>

                <!-- OTP form -->
                <div
                  v-if="showOtpForm"
                  class="px-4 ms-3 align-items-center form-container"
                >
                  <div class="otp-form">
                    <h2 v-if="showOtpForm">Email Verification</h2>
                    <p class="text-secondary mt-4 mb-3" v-if="showOtpForm">
                      Please check you Email for an OTP
                    </p>
                    <form @submit.prevent="validateOtp">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control border-0 border-bottom"
                          id="otp"
                          placeholder="Enter OTP"
                          v-model="otp"
                          maxlength="6"
                          pattern="\d{6}"
                          required
                        />
                        <label for="otp">Enter OTP</label>
                      </div>
                      <div class="text-center mt-4">
                        <button
                          type="submit"
                          class="btn btn-outline-primary w-75"
                        >
                          <span v-if="loader">
                            <span
                              class="spinner-border spinner-border-sm me-3"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Verify Account </span>
                          </span>
                          <span v-else> Verify Account </span>
                        </button>
                      </div>
                    </form>
                    <p class="text-center mt-3 mb-1">
                      Don't receive code?
                      <span>
                        <button
                          @click="resendOtp"
                          type="button"
                          class="btn text-primary border-0"
                          :disabled="resendDisabled"
                        >
                          Resend
                        </button>
                      </span>
                    </p>
                    <p v-if="resendDisabled" class="text-secondary text-center">
                      You can resend OTP in {{ resendTimer }} seconds.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Forgot Password Section -->
            <div class="col-md-6 ps-3 pt-5 pb-3 pe-4" v-if="forgotPassword">
              <h2 class="my-3 text-center">
                {{ isLanguage["Forgot Password"] }}
              </h2>
              <p class="text-secondary my-4 text-center px-4">
                {{
                  isLanguage[
                    "Please Enter the email where you will get the reset password link!"
                  ]
                }}
              </p>
              <form class="mt-3 mb-3 ps-3 pe-2 py-2">
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control border-0 border-bottom"
                    id="forgotPasswordEmail"
                    placeholder="name@example.com"
                    v-model="forgotPasswordEmail"
                    required
                  />
                  <label for="forgotPasswordEmail">{{
                    isLanguage["Email Address"]
                  }}</label>
                </div>
                <div class="text-center mt-3">
                  {{ isLanguage["Go Back to"] }}
                  <a href="#" @click="toggleForgotPassword">
                    {{ isLanguage["Log in"] }}
                  </a>
                </div>
                <div class="text-center mt-4">
                  <button
                    type="submit"
                    class="btn btn-outline-primary ms-3"
                    @click="sendResetEmail"
                    :disabled="loader"
                  >
                    <span v-if="loader">
                      <span
                        class="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>
                        <i class="fa-regular fa-paper-plane me-2"></i>
                        {{ isLanguage["Send"] }}
                      </span>
                    </span>
                    <span v-else>
                      <i class="fa-regular fa-paper-plane me-2"></i>
                      {{ isLanguage["Send"] }}
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <div class="col-md-6 d-md-block d-none p-0 position-relative">
              <div
                class="cross-icon-container cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="clearData"
              >
                <div class="cross-line"></div>
                <div class="cross-line"></div>
              </div>
              <img
                src="/empty-frame-globe-plane.jpg"
                alt="bg-img"
                class="img-fluid rounded-end-3 h-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <loading-screen v-if="loading" />
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import { useUserInfoStore } from "@/stores/userInfoStore";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "MainNav",
  components: {
    LoadingScreen,
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_IMAGE_URL,
      activeLink: "/home",
      selectedCountry: "",
      countries: ["Pakistan", "America", "Nigeria", "Canada"],
      currencies: ["PKR (Rs)", "USD ($)", "NGN (₦)", "CAD ($)"],
      languages: ["English", "French"],
      errorMessage: "",
      locationLoading: true,
      formType: "login",
      passwordVisible: false,
      confirmPasswordVisible: false,
      forgotPasswordVisible: false,
      forgotPassword: false,
      loader: false,

      // OTP form
      showOtpForm: false,
      showRegisterForm: true,
      otp: "",
      resendDisabled: false,
      resendTimer: 60,
      resendInterval: null,

      // Login form data
      loginEmail: "",
      loginPassword: "",

      // Forgot Password data
      forgotPasswordEmail: "",

      // Register form data
      registerFirstName: "",
      registerLastName: "",
      registerEmail: "",
      registerPassword: "",
      confirmPassword: "",
      registerSubmitted: false,
      registrationError: "",

      passwordBlurred: false,
      confirmPasswordBlurred: false,

      showAirportModal: true,

      selectedAirport: "",
      airports: [],
      searchTerm: "",
    };
  },

  computed: {
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    loading() {
      if (
        !useUserInfoStore().widgetIsLoading &&
        !useUserInfoStore().cardsIsLoading
      ) {
        return false;
      } else {
        return true;
      }
    },
    selectedLanguage() {
      const sl = useUserInfoStore().selectedLanguage;
      if (sl === "en") {
        return "English";
      } else if (sl === "fr") {
        return "French";
      }
      return sl;
    },
    userdata() {
      return useUserInfoStore().user;
    },
    city() {
      return useUserInfoStore().city;
    },
    isLoggedIn() {
      return useUserInfoStore().isLoggedIn;
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
    loginFormValid() {
      return this.loginEmail && this.loginPassword;
    },
    registerFormValid() {
      return (
        this.registerFirstName &&
        this.registerLastName &&
        this.registerEmail &&
        this.registerPassword &&
        this.confirmPassword === this.registerPassword
      );
    },
    passwordsMatch() {
      return this.registerPassword === this.confirmPassword;
    },
    filteredAirports() {
      return this.airports.filter(
        (airport) =>
          (airport.code &&
            airport.name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())) ||
          airport.city.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
    },
  },
  methods: {
    setselectedLanguage(data) {
      if (data === "English") {
        useUserInfoStore().setLanguage("en");
      } else if (data === "French") {
        useUserInfoStore().setLanguage("fr");
      }
      window.location.reload();
    },
    toggleFormType() {
      this.formType = this.formType === "login" ? "register" : "login";
      // this.clearData();
    },
    signInWithGoogle() {
      window.location.href = process.env.VUE_APP_API_URL + "/login/google";
    },
    signInWithFacebook() {
      window.location.href = process.env.VUE_APP_API_URL + "/login/facebook";
    },
    logout() {
      useUserInfoStore().clearUser();
      window.location.reload();
    },
    clearData() {
      this.loginEmail = "";
      this.loginPassword = "";
      this.registerFirstName = "";
      this.registerLastName = "";
      this.registerEmail = "";
      this.registerPassword = "";
      this.confirmPassword = "";

      this.registerSubmitted = false;
      if (this.formType !== "login") {
        this.formType = "login";
      }

      this.closeForgotPasswordForm();

      this.showRegisterForm = true;
      this.showOtpForm = false;
    },
    async login() {
      if (this.loginFormValid) {
        this.loader = true;
        try {
          const response = await axios.post(
            process.env.VUE_APP_API_URL + "/login",
            {
              email: this.loginEmail,
              password: this.loginPassword,
            },
          );

          if (response.status === 200) {
            const userData = response.data;
            const userInfoStore = useUserInfoStore();
            if (userData.role === 1 && this.selectedLanguage !== "English") {
              console.log("ok");
              userInfoStore.setLanguage("en");
              window.location.reload();
            }
            userInfoStore.setUser(userData);

            if (userData.role === 1 && this.selectedLanguage !== "English") {
              userInfoStore.setLanguage("en");
              window.location.reload();
            }

            // Close the modal
            $("#exampleModal").modal("hide");
            this.clearData();
            (this.loginEmail = ""),
              (this.loginPassword = ""),
              toast.success(this.isLanguage["Successfully Logged in!"]);
            this.loader = false;
          } else {
            toast.error(response.data.message);
            this.loader = false;
          }
        } catch (error) {
          toast.error(
            this.isLanguage["The provided credentials are incorrect."],
          );
          this.loader = false;
        }
      }
    },
    async register() {
      this.registerSubmitted = true;

      // Validate form fields
      if (!this.registerFormValid) {
        return;
      }

      this.loader = true;
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/register",
          {
            first_name: this.registerFirstName,
            last_name: this.registerLastName,
            email: this.registerEmail,
            password: this.registerPassword,
          },
        );

        if (response.status === 200) {
          const userData = response.data;
          toast.info(this.isLanguage["Please check your Email for an OTP!"]);
          this.loader = false;
          localStorage.setItem("user_id", userData.user_id);
          this.showOtpForm = true;
          this.showRegisterForm = false;
          this.startResendTimer();
        } else {
          toast.error(response.data.message);
          this.loader = false;
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data.email &&
          error.response.data.email.includes(
            "The email has already been taken.",
          )
        ) {
          toast.error(this.isLanguage["The email has already been taken."]);
        } else {
          toast.error(
            this.isLanguage[
              "An error occurred during registration. Please try again later."
            ],
          );
        }
      } finally {
        this.loader = false;
      }
    },
    async validateOtp() {
      this.loader = true;
      const user_id = localStorage.getItem("user_id");
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/validateOtp",
          {
            user_id: user_id,
            otp: this.otp,
          },
        );

        if (response.status === 200) {
          const userData = response.data;
          const userInfoStore = useUserInfoStore();
          userInfoStore.setUser(userData);
          toast.success(this.isLanguage["Successfully registered!"]);
          $("#exampleModal").modal("hide");
          this.clearData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(
          this.isLanguage["Failed to validate OTP. Please try again."],
        );
      } finally {
        this.loader = false;
      }
    },
    async resendOtp() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/resendOtp",
          {
            email: this.registerEmail,
          },
        );

        if (response.status === 200) {
          toast.success(this.isLanguage["OTP resent successfully!"]);
          this.startResendTimer();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(
          this.isLanguage["Failed to resend OTP. Please try again later."],
        );
      }
    },
    startResendTimer() {
      this.resendDisabled = true;
      this.resendTimer = 60;

      this.resendInterval = setInterval(() => {
        this.resendTimer -= 1;
        if (this.resendTimer <= 0) {
          clearInterval(this.resendInterval);
          this.resendDisabled = false;
        }
      }, 1000);
    },
    getCountryFlag(country) {
      let flag;
      switch (country) {
        case "Pakistan":
          flag = "<span class= 'fi fi-pk'></span>";
          break;
        case "America":
          flag = "<span class= 'fi fi-us'></span>";
          break;
        case "Nigeria":
          flag = "<span class= 'fi fi-ng'></span>";
          break;
        case "Canada":
          flag = "<span class= 'fi fi-ca'></span>";
          break;
        default:
          flag = "";
      }
      return flag || country;
    },
    async successHandler(position) {
      const latitude = position.coords.latitude || 56.1304;
      const longitude = position.coords.longitude || 106.3468;
      const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
      this.locationLoading = true;

      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${latitude},${longitude}`,
        );
        const data = await response.json();

        if (data.status === "OK") {
          const addressComponents = data.results[0].address_components;
          // const cityComponent = addressComponents.find((component) =>
          //   component.types.includes("locality"),
          // );

          // Fetch the country name more reliably
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country"),
          );
          const currentCountry = countryComponent
            ? countryComponent.long_name
            : "Canada";

          // Update the country based on presence in a predefined list
          this.selectedCountry = this.countries.includes(currentCountry)
            ? currentCountry
            : "Canada";

          if (currentCountry) {
            const airportCodes = require("airport-codes");

            // this.errorMessage = `No airport found in your city: ${cityComponent.long_name}. Select your Airport Manually.`;
            // toast.info(this.errorMessage);

            const airportsInCountry = airportCodes.where({
              country: currentCountry,
            });

            // Filter airports to only include those with a non-empty IATA code
            const validAirports = airportsInCountry.filter(
              (a) => a.get("iata") !== "",
            );

            // Haversine formula to calculate distance between two coordinates
            function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
              var R = 6371; // Radius of the earth in km
              var dLat = deg2rad(lat2 - lat1); // deg2rad below
              var dLon = deg2rad(lon2 - lon1);
              var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) *
                  Math.cos(deg2rad(lat2)) *
                  Math.sin(dLon / 2) *
                  Math.sin(dLon / 2);
              var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              var d = R * c; // Distance in km
              return d;
            }

            function deg2rad(deg) {
              return deg * (Math.PI / 180);
            }

            // Sort validAirports by nearest distance to the current position
            const sortedAirports = validAirports.sort((a, b) => {
              const distanceA = getDistanceFromLatLonInKm(
                latitude,
                longitude,
                a.get("latitude"),
                a.get("longitude"),
              );
              const distanceB = getDistanceFromLatLonInKm(
                latitude,
                longitude,
                b.get("latitude"),
                b.get("longitude"),
              );
              return distanceA - distanceB;
            });

            // Map the sorted airports to the desired format
            this.airports = sortedAirports.map((a) => ({
              name: a.get("name"),
              code: a.get("iata"),
              city: a.get("city"),
            }));
            this.selectAirport(this.airports[0].code);
            useUserInfoStore().setAirports(this.airports);
          } else {
            throw new Error("City not found");
          }
        } else {
          throw new Error("We are unable to access your location.");
        }
      } catch (error) {
        this.errorMessage = "We are unable to access your location! 66";
        console.error(error); // Log the real error to the console
        toast.error(this.errorMessage);
      } finally {
        this.locationLoading = false;
      }
    },
    selectAirport(code) {
      useUserInfoStore().setCity(code);
      this.selectedAirport = code;
    },
    filterAirports() {
      // This method is just a placeholder. Actual filtering logic is in the computed property.
    },
    closeModal() {
      $("#airportModal").modal("hide");
    },
    errorHandler(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.errorMessage =
            this.isLanguage[
              "Location access is disabled. Please enable location access."
            ];
          break;
        case error.POSITION_UNAVAILABLE:
          this.errorMessage =
            this.isLanguage["Location information is unavailable."];
          break;
        case error.TIMEOUT:
          this.errorMessage =
            this.isLanguage["The request to get user location timed out."];
          break;
        case error.UNKNOWN_ERROR:
          this.errorMessage = this.isLanguage["An unknown error occurred."];
          break;
      }
      this.successHandler({
        coords: { latitude: 56.1304, longitude: 106.3468 },
      });
      this.locationLoading = false;
      toast.info(this.errorMessage);
    },
    fetchPageContent() {
      const sl = useUserInfoStore().selectedLanguage;
      const data = useUserInfoStore().contentData;

      if (data.length === 0) {
        axios
          .get(process.env.VUE_APP_API_URL + `/page-contents/${sl}`)
          .then((response) => {
            useUserInfoStore().setContent(response.data);
          })
          .catch(() => {
            console.error(this.isLanguage["Error fetching page content"]);
          });
      }
    },
    toggleForgotPassword() {
      this.forgotPassword = !this.forgotPassword;
      this.forgotPasswordEmail = "";
    },
    closeForgotPasswordForm() {
      this.forgotPassword = false;
      this.formType = "login";
    },
    async sendResetEmail() {
      this.loader = true;

      // Check if the email is provided
      if (!this.forgotPasswordEmail) {
        toast.error(this.isLanguage["Please provide valid Email."]);
        this.loader = false;
        return;
      }

      try {
        await axios.post(process.env.VUE_APP_API_URL + "/forgot-password", {
          email: this.forgotPasswordEmail,
        });
        toast.success(this.isLanguage["Email Link Successfully."]);
        this.toggleForgotPassword();
        this.loader = false;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.info(
            this.isLanguage[
              "We can't find a user with that email address, Please enter registered email."
            ],
          );
        } else {
          toast.error(this.isLanguage["Failed to send Email Link."]);
        }
      } finally {
        this.loader = false;
      }
    },
  },
  mounted() {
    this.fetchPageContent();
    const modalElement = document.getElementById("exampleModal");
    modalElement.addEventListener("click", (event) => {
      if (event.target === modalElement) {
        // If clicked outside of modal, clear input fields
        this.clearData();
      }
    });
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        this.successHandler,
        this.errorHandler,
      );
    } else {
      this.errorMessage = "Geolocation is not supported by this browser.";
      this.locationLoading = false;
    }
  },
};
</script>

<style scoped>
.active {
  border: 2px solid #006ee3;
}

.dropdown-menu {
  max-height: 300px;
  max-width: 250px;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
