<template>
  <div class="min-vh-80">
    <div
      v-for="(img, index) in bgImg"
      :key="index"
      class="main-banner-bg height-300px"
      :style="{ backgroundImage: `url('${backendUrl + img.images}')` }"
    >
      <div class="bg-overlay">
        <div class="container overflow-x-hidden pt-5">
          <h1 class="text-white">{{ isLanguage["My Information"] }}</h1>
          <h5 class="text-white">{{ isLanguage["View My Details"] }}</h5>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="minus-margin-7">
        <div class="row pb-5">
          <div class="col-md-3"><ProfileTabs /></div>
          <div class="col-md-9">
            <div class="card border-0 shadow">
              <div class="card-header">
                <ul
                  class="nav nav-tabs card-header-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="myDetails-tab"
                      data-bs-toggle="tab"
                      href="#myDetails"
                      role="tab"
                      aria-controls="myDetails"
                      aria-selected="true"
                    >
                      {{ isLanguage["My Details"] }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="myDetails"
                    role="tabpanel"
                    aria-labelledby="myDetails-tab"
                  >
                    <h4>{{ isLanguage["Profile"] }}</h4>
                    <!-- Profile Image -->
                    <div v-if="selectedImage || userdata.image">
                      <img
                        v-if="selectedImage"
                        :src="image"
                        alt="Selected Image Preview"
                        class="user-image-big"
                      />
                      <img
                        v-else
                        :src="backendUrl + userdata.image"
                        alt="User Image"
                        class="user-image-big"
                      />
                    </div>
                    <div v-else>
                      <!-- Default Avatar Image -->
                      <img
                        src="/avatar.jpg"
                        alt="Default Avatar"
                        class="user-image-big"
                      />
                    </div>

                    <div v-if="!inputDisabled" class="mt-3">
                      <button
                        class="btn btn-outline-primary rounded-1 px-4"
                        @click="openFileUpload"
                      >
                        {{ isLanguage["Upload Image"] }}
                      </button>
                    </div>

                    <hr class="py-2" />

                    <div class="d-flex justify-content-between">
                      <h4>{{ isLanguage["Log in Information"] }}</h4>
                      <button
                        class="btn btn-outline-primary rounded-1 px-4"
                        @click="toggleInput"
                      >
                        {{ inputDisabled ? "Edit" : "Cancel" }}
                      </button>
                    </div>
                    <form>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="input-group mb-3">
                            <span class="input-group-text" id="email">
                              {{ isLanguage["Email Address"] }}
                            </span>
                            <input
                              type="email"
                              class="form-control"
                              v-model="email"
                              aria-describedby="email"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-3">
                            <span class="input-group-text" id="password">
                              {{ isLanguage["Password"] }}
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              value="12345678"
                              aria-describedby="password"
                              disabled
                            />
                          </div>
                          <div v-if="!inputDisabled" class="mt-3">
                            <button
                              type="button"
                              class="btn btn-outline-primary rounded-1 px-4"
                              data-bs-toggle="modal"
                              data-bs-target="#updatePasswordModal"
                            >
                              {{ isLanguage["Update Password"] }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <hr class="py-2" />

                    <h4>{{ isLanguage["Personal Information"] }}</h4>
                    <form class="row mt-3">
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="first-name">
                            {{ isLanguage["First Name"] }}
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="first_name"
                            aria-describedby="first-name"
                            :disabled="inputDisabled"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="last-name">
                            {{ isLanguage["Last Name"] }}
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="last_name"
                            aria-describedby="last-name"
                            :disabled="inputDisabled"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <label class="input-group-text" for="gender">
                            {{ isLanguage["Gender"] }}
                          </label>
                          <select
                            class="form-select"
                            id="gender"
                            v-model="gender"
                            :disabled="inputDisabled"
                          >
                            <option selected>
                              {{ isLanguage["Not Specified"] }}
                            </option>
                            <option value="male">
                              {{ isLanguage["Male"] }}
                            </option>
                            <option value="female">
                              {{ isLanguage["Female"] }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <label class="input-group-text" for="title">
                            {{ isLanguage["Title"] }}
                          </label>
                          <select
                            class="form-select"
                            id="title"
                            v-model="title"
                            :disabled="inputDisabled"
                          >
                            <option selected>
                              {{ isLanguage["Not Specified"] }}
                            </option>
                            <option value="mr">{{ isLanguage["Mr"] }}</option>
                            <option value="mrs">{{ isLanguage["Mrs"] }}</option>
                            <option value="miss">
                              {{ isLanguage["Miss"] }}
                            </option>
                            <option value="dr">{{ isLanguage["Dr"] }}</option>
                            <option value="rev">{{ isLanguage["Rev"] }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="airport">
                            {{ isLanguage["Home Airport"] }}
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="home_airport"
                            aria-describedby="airport"
                            :disabled="inputDisabled"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <label class="input-group-text" for="country">
                            {{ isLanguage["Country"] }}
                          </label>
                          <select
                            class="form-select"
                            id="country"
                            v-model="country"
                            :disabled="inputDisabled"
                          >
                            <option selected>
                              {{ isLanguage["Not Specified"] }}
                            </option>
                            <option value="canada">
                              {{ isLanguage["Canada"] }}
                            </option>
                            <option value="america">
                              {{ isLanguage["America"] }}
                            </option>
                            <option value="nigeria">
                              {{ isLanguage["Nigeria"] }}
                            </option>
                            <option value="pakistan">
                              {{ isLanguage["Pakistan"] }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </form>

                    <hr class="py-2" />

                    <div class="row">
                      <div class="col-12 text-end">
                        <button
                          v-if="!inputDisabled"
                          class="btn btn-outline-primary rounded-1 px-4"
                          @click="updateInfo"
                          :disabled="savingData"
                        >
                          <span v-if="savingData">
                            <span
                              class="spinner-border spinner-border-sm me-3"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span>{{ isLanguage["Save Information"] }}</span>
                          </span>
                          <span v-else>{{
                            isLanguage["Save Information"]
                          }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="updatePasswordModal"
    tabindex="-1"
    aria-labelledby="updatePasswordLabel"
    aria-hidden="true"
  >
    <!-- Modal content -->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="updatePasswordLabel">
            {{ isLanguage["Update Password"] }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Old Password input -->
          <div class="form-floating mb-3">
            <input
              :type="oldPasswordVisible ? 'text' : 'password'"
              class="form-control border-0 border-bottom"
              id="oldPassword"
              placeholder="Old Password"
              v-model="oldPassword"
              required
            />
            <label for="oldPassword">{{ isLanguage["Old Password"] }}</label>
            <i
              @click="oldPasswordVisible = !oldPasswordVisible"
              class="eyeButton cursor-pointer"
              :class="
                oldPasswordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
              "
            ></i>
          </div>
          <!-- New Password input -->
          <div class="form-floating mb-3">
            <input
              :type="newPasswordVisible ? 'text' : 'password'"
              class="form-control border-0 border-bottom"
              id="newPassword"
              placeholder="New Password"
              @blur="passwordBlurred = true"
              v-model="newPassword"
              required
            />
            <label for="newPassword">{{ isLanguage["New Password"] }}</label>
            <i
              @click="newPasswordVisible = !newPasswordVisible"
              class="eyeButton cursor-pointer"
              :class="
                newPasswordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
              "
            ></i>
            <div
              v-if="passwordBlurred && (newPassword?.length ?? 0) < 8"
              class="text-danger"
            >
              {{ isLanguage["Password must be at least 8 characters long."] }}
            </div>
          </div>
          <!-- Confirm Password input -->
          <div class="form-floating mb-3">
            <input
              :type="confirmPasswordVisible ? 'text' : 'password'"
              class="form-control border-0 border-bottom"
              id="confirmPassword"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              @blur="confirmPasswordBlurred = true"
              required
            />
            <label for="confirmPassword">{{
              isLanguage["Confirm Password"]
            }}</label>
            <i
              @click="confirmPasswordVisible = !confirmPasswordVisible"
              class="eyeButton cursor-pointer"
              :class="
                confirmPasswordVisible
                  ? 'fa-solid fa-eye-slash'
                  : 'fa-solid fa-eye'
              "
            ></i>
          </div>
          <div
            v-if="confirmPasswordBlurred && !passwordsMatch"
            class="text-danger"
          >
            {{ isLanguage["Passwords do not match."] }}
          </div>
        </div>
        <div class="modal-footer">
          <!-- "Save changes" button -->
          <button
            type="button"
            class="btn btn-outline-primary rounded-1 px-4"
            @click="updatePassword"
          >
            {{ isLanguage["Save changes"] }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ isLanguage["Close"] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileTabs from "@/components/ProfileTabs.vue";
import { useUserInfoStore } from "@/stores/userInfoStore";
import axios from "axios";
import { toast } from "vue3-toastify";

export default {
  name: "MyInformation",
  components: { ProfileTabs },
  data() {
    return {
      backendUrl: process.env.VUE_APP_IMAGE_URL,
      initialUserData: {},
      savingData: false,
      updatingPassword: false,
      image: null,
      previousData: null,
      inputDisabled: true,
      selectedImage: null,
      oldPasswordVisible: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordBlurred: false,
      confirmPasswordBlurred: false,
    };
  },
  computed: {
    language() {
      return localStorage.getItem("language");
    },
    isAdmin() {
      return useUserInfoStore().isAdmin;
    },
    isLoggedIn() {
      return useUserInfoStore().isLoggedIn;
    },
    isLanguage() {
      return useUserInfoStore().isLanguage;
    },
    bgImg() {
      return useUserInfoStore().bgImg;
    },
    token() {
      return useUserInfoStore().userToken();
    },
    userdata() {
      return useUserInfoStore().user;
    },
    passwordsMatch() {
      return this.newPassword === this.confirmPassword;
    },
  },
  mounted() {
    this.getImg();
    this.initializeUserData();
    this.addModalEventListeners();
  },
  methods: {
    async getImg() {
      try {
        const url =
          process.env.VUE_APP_API_URL + `/card/get/bg_img/${this.language}`;
        const response = await axios.get(url);
        useUserInfoStore().setBgImg(response.data);
      } catch (error) {
        toast.error(this.isLanguage["Error on getting card"]);
      }
    },
    toggleInput() {
      this.inputDisabled = !this.inputDisabled;
      if (this.inputDisabled) {
        this.resetFormFields();
      }
    },
    resetFormFields() {
      this.email = this.userdata.email;
      this.first_name = this.userdata.first_name;
      this.last_name = this.userdata.last_name;
      this.gender = this.userdata.gender;
      this.title = this.userdata.title;
      this.home_airport = this.userdata.home_airport;
      this.country = this.userdata.country;
      this.selectedImage = null;
    },
    openFileUpload() {
      const input = document.createElement("input");
      input.type = "file";
      input.click();

      input.onchange = async () => {
        this.selectedImage = input.files[0];
        if (!this.selectedImage) return;
        this.image = URL.createObjectURL(this.selectedImage);
      };
    },
    async updateInfo() {
      this.savingData = true;
      const updatedUserData = new FormData();

      // Add only the fields that have been edited or are not empty
      if (this.selectedImage) {
        const blob = await fetch(this.image).then((res) => res.blob());
        updatedUserData.append("image", blob);
      }
      if (this.first_name && this.first_name !== this.userdata.first_name) {
        updatedUserData.append("first_name", this.first_name);
      }
      if (this.last_name && this.last_name !== this.userdata.last_name) {
        updatedUserData.append("last_name", this.last_name);
      }
      if (this.country && this.country !== this.userdata.country) {
        updatedUserData.append("country", this.country);
      }
      if (this.gender && this.gender !== this.userdata.gender) {
        updatedUserData.append("gender", this.gender);
      }
      if (this.title && this.title !== this.userdata.title) {
        updatedUserData.append("title", this.title);
      }
      if (
        this.home_airport &&
        this.home_airport !== this.userdata.home_airport
      ) {
        updatedUserData.append("home_airport", this.home_airport);
      }

      // Check if there are any fields to update
      if (
        !updatedUserData.has("image") &&
        !updatedUserData.has("first_name") &&
        !updatedUserData.has("last_name") &&
        !updatedUserData.has("country") &&
        !updatedUserData.has("gender") &&
        !updatedUserData.has("title") &&
        !updatedUserData.has("home_airport")
      ) {
        toast.error("No changes to update.");
        this.savingData = false;
        return;
      }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/user/update",
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        );

        response.data.user.token = this.token;
        useUserInfoStore().setUser(response.data.user);
        toast.success(this.isLanguage["Updated Information Successfully!"]);
        this.toggleInput();

        // After saving
        setTimeout(() => {
          this.savingData = false;
          this.inputDisabled = true;
          // Save the updated state as initial state
          // this.initialUserData = JSON.parse(JSON.stringify(this.userdata));
        }, 2000);
      } catch (error) {
        toast.error(this.isLanguage["Server Error, Please Try Again!"]);
        this.savingData = false;
      }
    },
    async initializeUserData() {
      const userData = this.userdata;
      this.email = userData.email || null;
      this.password = userData.password || null;
      this.first_name = userData.first_name || null;
      this.last_name = userData.last_name || null;
      this.country = userData.country || null;
      this.gender = userData.gender || null;
      this.title = userData.title || null;
      this.home_airport = userData.home_airport || null;
      this.image = userData.image || null;
    },
    async updatePassword() {
      this.updatingPassword = true;

      // Check if required fields are empty
      if (!this.oldPassword || !this.newPassword) {
        toast.error(
          this.isLanguage["Please provide both old and new passwords."],
        );
        this.updatingPassword = false;
        return;
      }

      // Check if new password is the same as the old password
      if (this.oldPassword === this.newPassword) {
        toast.error(
          this.isLanguage[
            "New password should not be the same as the old password."
          ],
        );
        this.updatingPassword = false;
        return;
      }

      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "/user/password/update",
          {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        );

        toast.success(this.isLanguage["Password Updated Successfully."]);

        this.updatingPassword = false;
        this.resetPasswordFields();
        $("#updatePasswordModal").modal("hide");
      } catch (error) {
        toast.error(this.isLanguage["Failed to update password."]);
      }
    },

    addModalEventListeners() {
      const updatePasswordModal = document.getElementById(
        "updatePasswordModal",
      );
      updatePasswordModal.addEventListener(
        "hidden.bs.modal",
        this.resetPasswordFields,
      );
    },

    resetPasswordFields() {
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
      this.passwordBlurred = false;
      this.confirmPasswordBlurred = false;
      this.oldPasswordVisible = false;
      this.newPasswordVisible = false;
      this.confirmPasswordVisible = false;
    },
  },
};
</script>
