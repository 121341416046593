import { defineStore } from "pinia";
import { decryptionFunctions } from "@/encryption";

export const useUserInfoStore = defineStore("userInfo", {
  state: () => ({
    selectedLanguage: localStorage.getItem("language") || "en",
    user: localStorage.getItem("user")
      ? JSON.parse(
          decryptionFunctions.decryptData(localStorage.getItem("user")),
        )
      : {},
    contentData: [],
    languages: [
      {
        en: {
          Home: "Home",
          "Flights Deals": "Flights Deals",
          "Hotels Deals": "Hotels Deals",
          "Rides Deals": "Rides Deals",
          Support: "Support",
          "Contact us": "Contact us",
          "My Information": "My Information",
          Logout: "Logout",
          "Log in": "Log in",
          Or: "Or",
          "Install App": "Install App",
          "LET’S CONNECT": "LET’S CONNECT",
          Help: "Help",
          "Terms & Conditions": "Terms & Conditions",
          About: "About",
          LEGAL: "LEGAL",
          "Cookie Policy": "Cookie Policy",
          "Privacy policy": "Privacy policy",
          Company: "Company",
          PARTNERS: "PARTNERS",
          "ABOUT US": "ABOUT US",
          "View My Details": "View My Details",
          "My Trips": "My Trips",
          "My Rewards": "My Rewards",
          "My Deals": "My Deals",
          Profile: "Profile",
          "Log in Information": "Log in Information",
          "Email Address": "Email Address",
          Password: "Password",
          "Personal Information": "Personal Information",
          "First Name": "First Name",
          "Last Name": "Last Name",
          Gender: "Gender",
          Title: "Title",
          "Home Airport": "Home Airport",
          Country: "Country",
          Edit: "Edit",
          Cancel: "Cancel",
          "Upload Image": "Upload Image",
          "Update Password": "Update Password",
          "Save Information": "Save Information",
          "Old Password": "Old Password",
          "New Password": "New Password",
          "Confirm Password": "Confirm Password",
          "Remember me": "Remember me",
          "Forgot Password": "Forgot Password",
          "Reset Password": "Reset Password",
          "Please Enter the email where you will get the reset password link!":
            "Please Enter the email where you will get the reset password link!",
          "Don't have an account?": "Don't have an account?",
          Register: "Register",
          "Already have an account?": "Already have an account?",
          "Go Back to": "Go Back to",
          Send: "Send",
          "Explore Everywhere": "Explore Everywhere",
          Hotels: "Hotels",
          "Car Hire": "Car Hire",
          "Password must be at least 8 characters long.":
            "Password must be at least 8 characters long.",
          "Passwords do not match.": "Passwords do not match.",
          "Successfully Logged in!": "Successfully Logged in!",
          "Error occurred during login:": "Error occurred during login:",
          "The provided credentials are incorrect.":
            "The provided credentials are incorrect.",
          "Successfully registered!": "Successfully registered!",
          "An error occurred during registration. Please try again later.":
            "An error occurred during registration. Please try again later.",
          "Error fetching page content": "Error fetching page content",
          "Please provide valid Email.": "Please provide valid Email.",
          "Email Link Successfully.": "Email Link Successfully.",
          "Failed to send Email Link.": "Failed to send Email Link.",
          "Error on getting card": "Error on getting card",
          "Please fill in all required fields.":
            "Please fill in all required fields.",
          "Updated Information Successfully!":
            "Updated Information Successfully!",
          "Server Error, Please Try Again!": "Server Error, Please Try Again!",
          "Please provide both old and new passwords.":
            "Please provide both old and new passwords.",
          "Password Updated Successfully.": "Password Updated Successfully.",
          "Failed to update password.": "Failed to update password.",
          "Error on getting User Data.": "Error on getting User Data.",
          "Content updated successfully!": "Content updated successfully!",
          "Error updating content": "Error updating content",
          "Not Specified": "Not Specified",
          Male: "Male",
          Female: "Female",
          Mr: "Mr",
          Mrs: "Mrs",
          Miss: "Miss",
          Dr: "Dr",
          Rev: "Rev",
          Canada: "Canada",
          America: "America",
          Nigeria: "Nigeria",
          Pakistan: "Pakistan",
          or: "or",
          "Coming Soon": "Coming Soon",
          "Card Updated Successfully.": "Card Updated Successfully.",
          "Error updating card": "Error updating card",
          "Card Added Successfully.": "Card Added Successfully.",
          "Error adding card": "Error adding card",
          "The email has already been taken.":
            "The email has already been taken.",
          "Save changes": "Save changes",
          Close: "Close",
          "Please check your Email for an OTP!":
            "Please check your Email for an OTP!",
          "Failed to validate OTP. Please try again.":
            "Failed to validate OTP. Please try again.",
          "OTP resent successfully!": "OTP resent successfully!",
          "No airport found in your city": "No airport found in your city",
          "Select your Airport Manually": "Select your Airport Manually",
          "City not found": "City not found",
          "We are unable to access your location.":
            "We are unable to access your location.",
          "Location access is disabled. Please enable location access.":
            "Location access is disabled. Please enable location access.",
          "The request to get user location timed out.":
            "The request to get user location timed out.",
          "An unknown error occurred.": "An unknown error occurred.",
          "New password should not be the same as the old password.":
            "New password should not be the same as the old password.",
          "We can't find a user with that email address, Please enter registered email.":
            "We can't find a user with that email address, Please enter registered email.",
        },
        fr: {
          Home: "Accueil",
          "Flights Deals": "Offres de Vols",
          "Hotels Deals": "Offres d'Hôtels",
          "Rides Deals": "Offres de Transport",
          Support: "Soutien",
          "Contact us": "Nous contacter",
          "My Information": "Mes Informations",
          Logout: "Déconnexion",
          "Log in": "Connexion",
          Or: "Ou",
          "Install App": "Installer l'application",
          "LET’S CONNECT": "CONNECTONS-NOUS",
          Help: "Aide",
          "Terms & Conditions": "Conditions Générales",
          About: "À propos",
          LEGAL: "LÉGAL",
          "Cookie Policy": "Politique de Cookies",
          "Privacy policy": "Politique de Confidentialité",
          Company: "Entreprise",
          PARTNERS: "PARTENAIRES",
          "ABOUT US": "À PROPOS DE NOUS",
          "View My Details": "Voir Mes Détails",
          "My Trips": "Mes Voyages",
          "My Rewards": "Mes Récompenses",
          "My Deals": "Mes Offres",
          Profile: "Profil",
          "Log in Information": "Informations de Connexion",
          "Email Address": "Adresse Email",
          Password: "Mot de Passe",
          "Personal Information": "Informations Personnelles",
          "First Name": "Prénom",
          "Last Name": "Nom",
          Gender: "Genre",
          Title: "Titre",
          "Home Airport": "Aéroport de Domicile",
          Country: "Pays",
          Edit: "Modifier",
          Cancel: "Annuler",
          "Upload Image": "Télécharger Image",
          "Update Password": "Mettre à Jour le Mot de Passe",
          "Save Information": "Enregistrer les Informations",
          "Old Password": "Ancien Mot de Passe",
          "New Password": "Nouveau Mot de Passe",
          "Confirm Password": "Confirmer le Mot de Passe",
          "Remember me": "Se souvenir de moi",
          "Forgot Password": "Mot de Passe Oublié",
          "Reset Password": "Réinitialiser le Mot de Passe",
          "Please Enter the email where you will get the reset password link!":
            "Veuillez entrer l'email où vous recevrez le lien de réinitialisation du mot de passe!",
          "Don't have an account?": "Vous n'avez pas de compte?",
          Register: "S'inscrire",
          "Already have an account?": "Vous avez déjà un compte?",
          "Go Back to": "Retourner à",
          Send: "Envoyer",
          "Explore Everywhere": "Explorer Partout",
          Hotels: "Hôtels",
          "Car Hire": "Location de Voiture",
          "Password must be at least 8 characters long.":
            "Le mot de passe doit comporter au moins 8 caractères.",
          "Passwords do not match.": "Les mots de passe ne correspondent pas.",
          "Successfully Logged in!": "Connexion réussie!",
          "Error occurred during login:":
            "Une erreur est survenue lors de la connexion:",
          "The provided credentials are incorrect.":
            "Les identifiants fournis sont incorrects.",
          "Successfully registered!": "Inscription réussie!",
          "An error occurred during registration. Please try again later.":
            "Une erreur est survenue lors de l'inscription. Veuillez réessayer plus tard.",
          "Error fetching page content":
            "Erreur lors de la récupération du contenu de la page",
          "Please provide valid Email.": "Veuillez fournir un email valide.",
          "Email Link Successfully.": "Lien Email Envoyé.",
          "Failed to send Email Link.": "Échec de l'envoi du lien par email.",
          "Error on getting card": "Erreur lors de l'obtention de la carte",
          "Please fill in all required fields.":
            "Veuillez remplir tous les champs obligatoires.",
          "Updated Information Successfully!":
            "Informations mises à jour avec succès!",
          "Server Error, Please Try Again!":
            "Erreur du serveur, veuillez réessayer!",
          "Please provide both old and new passwords.":
            "Veuillez fournir l'ancien et le nouveau mot de passe.",
          "Password Updated Successfully.":
            "Mot de passe mis à jour avec succès.",
          "Failed to update password.":
            "Échec de la mise à jour du mot de passe.",
          "Error on getting User Data.":
            "Erreur lors de l'obtention des données utilisateur.",
          "Content updated successfully!": "Contenu mis à jour avec succès!",
          "Error updating content": "Erreur lors de la mise à jour du contenu",
          "Not Specified": "Non spécifié",
          Male: "Homme",
          Female: "Femme",
          Mr: "M.",
          Mrs: "Mme",
          Miss: "Mlle",
          Dr: "Dr",
          Rev: "Révérend",
          Canada: "Canada",
          America: "Amérique",
          Nigeria: "Nigéria",
          Pakistan: "Pakistan",
          or: "ou",
          "Coming Soon": "À venir",
          "Card Updated Successfully.": "Carte mise à jour avec succès.",
          "Error updating card": "Erreur lors de la mise à jour de la carte",
          "Card Added Successfully.": "Carte ajoutée avec succès.",
          "Error adding card": "Erreur lors de l'ajout de la carte",
          "The email has already been taken.":
            "Le couriel a déja été pris en compte.",
          "Save changes": "Sauvegarder les modifications",
          Close: "Fermer",
          "Please check your Email for an OTP!":
            "Veuillez vérifier votre e-mail pour un OTP!",
          "Failed to validate OTP. Please try again.":
            "Échec de la validation d'OTP. Veuillez réessayer.",
          "OTP resent successfully!": "OTP renvoyé avec succès!",
          "No airport found in your city":
            "Aucun aéroport trouvé dans votre ville",
          "Select your Airport Manually":
            "Aucun aéroport trouvé dans votre ville",
          "City not found": "Ville introuvable",
          "We are unable to access your location.":
            "Nous ne pouvons pas accéder à votre emplacement.",
          "Location access is disabled. Please enable location access.":
            "L'accès à la localisation est désactivé. Veuillez activer l'accès à la localisation.",
          "The request to get user location timed out.":
            "La demande d'obtention de la localisation de l'utilisateur a expiré.",
          "An unknown error occurred.": "Une erreur inconnue est survenue.",
          "New password should not be the same as the old password.":
            "Le nouveau mot de passe ne doit pas être le même que l'ancien mot de passe.",
          "We can't find a user with that email address, Please enter registered email.":
            "Nous ne trouvons pas d'utilisateur avec cette adresse e-mail. Veuillez saisir l'adresse e-mail enregistrée.",
        },
      },
    ],
    airports: "",
    city: "",
    widgetIsLoading: false,
    cardsIsLoading: false,
    faq: [],
    help: [],
    bgImg: [],
    rides: [],
    terms: [],
    about: [],
    hotels: [],
    cookie: [],
    promo1: [],
    promo2: [],
    promo3: [],
    privacy: [],
    flights: [],
    company: [],
    feature1: [],
    feature2: [],
    feature3: [],
    adTitle1: [],
    adTitle2: [],
    bannerImg: [],
    flightAds: [],
    aboutCard1: [],
    aboutCard2: [],
    aboutCard3: [],
    aboutLink1: [],
    aboutLink2: [],
    aboutLink3: [],
    aboutLink4: [],
    aboutLink5: [],
    adContent1: [],
    adContent2: [],
    adContent3: [],
    adContent4: [],
    ridesTitle: [],
    hotelsTitle: [],
    flightsTitle: [],
  }),

  actions: {
    setUser(userData) {
      this.user = userData;
      const encryptedData = decryptionFunctions.encryptData(
        JSON.stringify(userData),
      );
      localStorage.setItem("user", encryptedData);
    },
    setLanguage(language) {
      this.selectedLanguage = language;
      localStorage.setItem("language", language);
    },
    setCity(city) {
      this.city = city;
    },
    setAirports(airports) {
      this.airports = airports;
    },
    clearUser() {
      this.user = null;
      localStorage.removeItem("user");
    },
    userToken() {
      return this.user.token;
    },

    // Contents API
    setContent(data) {
      this.contentData = data;
      this.privacy = data[0];
      this.faq = data[1];
      this.about = data[2];
      this.help = data[3];
      this.terms = data[4];
      this.cookie = data[5];
      this.company = data[6];
      this.aboutCard1 = data[7];
      this.aboutCard2 = data[8];
      this.aboutCard3 = data[9];
      this.aboutLink1 = data[10];
      this.aboutLink2 = data[11];
      this.aboutLink3 = data[12];
      this.aboutLink4 = data[13];
      this.aboutLink5 = data[14];
      this.feature1 = data[15];
      this.feature2 = data[16];
      this.feature3 = data[17];
      this.adContent1 = data[18];
      this.adContent2 = data[19];
      this.adContent3 = data[20];
      this.adContent4 = data[21];
      this.promo1 = data[22];
      this.promo2 = data[23];
      this.promo3 = data[24];
      this.adTitle1 = data[25];
      this.adTitle2 = data[26];
      this.flightsTitle = data[27];
      this.hotelsTitle = data[28];
      this.ridesTitle = data[29];
    },
    updateContent(data, index) {
      data.id = this.contentData[index].id;
      this.contentData[index] = data;
      if (index === 0) {
        this.privacy = data;
      }
      if (index === 1) {
        this.faq = data;
      }
      if (index === 2) {
        this.about = data;
      }
      if (index === 3) {
        this.help = data;
      }
      if (index === 4) {
        this.terms = data;
      }
      if (index === 5) {
        this.cookie = data;
      }
      if (index === 6) {
        this.company = data;
      }
      if (index === 7) {
        this.aboutCard1 = data;
      }
      if (index === 8) {
        this.aboutCard2 = data;
      }
      if (index === 9) {
        this.aboutCard3 = data;
      }
      if (index === 10) {
        this.aboutLink1 = data;
      }
      if (index === 11) {
        this.aboutLink2 = data;
      }
      if (index === 12) {
        this.aboutLink3 = data;
      }
      if (index === 13) {
        this.aboutLink4 = data;
      }
      if (index === 14) {
        this.aboutLink5 = data;
      }
      if (index === 15) {
        this.feature1 = data;
      }
      if (index === 16) {
        this.feature2 = data;
      }
      if (index === 17) {
        this.feature3 = data;
      }
      if (index === 18) {
        this.adContent1 = data;
      }
      if (index === 19) {
        this.adContent2 = data;
      }
      if (index === 20) {
        this.adContent3 = data;
      }
      if (index === 21) {
        this.adContent4 = data;
      }
      if (index === 22) {
        this.promo1 = data;
      }
      if (index === 23) {
        this.promo2 = data;
      }
      if (index === 24) {
        this.promo3 = data;
      }
      if (index === 25) {
        this.adTitle1 = data;
      }
      if (index === 26) {
        this.adTitle2 = data;
      }
      if (index === 27) {
        this.flightsTitle = data;
      }
      if (index === 28) {
        this.hotelsTitle = data;
      }
      if (index === 29) {
        this.ridesTitle = data;
      }
    },

    // Cards API
    setFlights(flights) {
      this.flights = flights;
    },
    updateFlight(index, flightData) {
      this.flights[index] = flightData;
    },
    setHotels(hotels) {
      this.hotels = hotels;
    },
    updateHotels(index, hotelData) {
      this.hotels[index] = hotelData;
    },
    setRides(rides) {
      this.rides = rides;
    },
    updateRides(index, rideData) {
      this.rides[index] = rideData;
    },
    setBannerImg(bannerImg) {
      this.bannerImg = bannerImg;
    },
    updateBannerImg(index, imgUrl) {
      this.bannerImg[index] = imgUrl;
    },
    setBgImg(bgImg) {
      this.bgImg = bgImg;
    },
    updateBgImg(index, imgUrl) {
      this.bgImg[index] = imgUrl;
    },
    setFlightAds(flightAds) {
      this.flightAds = flightAds;
    },
    updateFlightAds(index, flightAdsData) {
      this.flightAds[index] = flightAdsData;
    },
    formatDate(date) {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      return yyyy + "-" + mm + "-" + dd;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user && !!state.user.token;
    },
    isAdmin: (state) => {
      return state.user && state.user.role === 1;
    },
    isLanguage: (state) => {
      const languageData = state.languages[0];
      return languageData[state.selectedLanguage] || languageData["en"]; // Fallback to English if selected language is not found
    },
  },
});
